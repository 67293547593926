<script lang="ts">
    export let param = ""
    export let action = ""
    import Info from "./DiagnoseInfo.svelte"
    import List from "./DiagnoseList.svelte"
    import { userAuthInfo } from "@common/store/userProfile"
    import { goto } from "@common/routing/router"
    // bf67b8ac-6160-4bb7-8de2-603823815438
</script>
<style>
    article {
        background: var(--mdc-theme-background);
        padding: 2rem;
        border-radius: 4px;
    }
    article header {
        background: var(--mdc-theme-background);
        display: flex;
        padding: 2rem;
        justify-content: center;
    }

    article section {
        padding: 1rem;
    }
    @media only screen and (max-width: 640px) {
        article {
            padding: 1rem;
        }
        article header {
            padding: 1rem;
        }
    }
</style>
<article>
    {#if param}
    <header>
        <h1>Diagnose</h1>
    </header>
    {/if}
    <section>
        <div>
            {#if param}
                <Info param={param} action={action} />
            {:else}
                {#if $userAuthInfo.loggedIn}
                    <List />
                {:else}
                    <h3>Ingen diagnose å vise</h3>
                    <a href="/signin" on:click={goto}>
                        <h4>Logg inn for å se liste med dine diagnoser</h4>
                    </a>
                {/if}
                
            {/if}
        </div>
</article>