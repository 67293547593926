/*
  do some initials like check if is logged in, also import css
*/
const baseEl = document.createElement("base")
baseEl.href = window.location.origin
document.head.appendChild(baseEl)

import firebase from "firebase/app"
import userauth from "@common/services/userauth"
import urlParser from "@common/funcs/urlParser"
// import auth from "@common/services/authentication"
import { langInit } from "@common/services/lang"
import { appInitialized } from "@common/store"
import { setInitialHref } from "@common/routing/router"
import { loadGoogleTag, LoadHubspot } from "@common/services/googleTagManager"
// import "./components/Stars/StarsSvgDef.js";
import "@common/components/Spinner"
import "@common/components/ratingDynStars"
import "@common/components/CheckMarkGreen"
import "@common/components/Formatters/DateViewer"
import "@common/components/Formatters/DateTimeViewer"
import "@common/components/Formatters/DistanceViewer"
import "@common/components/Files/ImageViewer"
import "@common/components/Files/ImageCarusel"
import "@common/components/Files/AssetImageViewer"
import "@common/components/FontAwesomeIcon"
import "@common/components/DirectionSign"
import "@common/components/SignWidget"
import "@common/components/BdButton"
import "@common/components/Blog/BlogPreview"
import "@common/components/Blog/BlogFrontPage"
import "@common/components/Blog/BlogWidget"
import "@common/components/Blog/HtmlPreview"

import "@common/components/RichText/ProseEditor"
import "@common/components/Users/AuthProvider"
import "@common/components/PopdownMenu"

import "@app/views/departments/departmentCard"
import "@app/views/departments/departmentList"
import "@app/views/departments/productLink"
import "@app/views/departments/productLinkList"
import "@common/components/LocationsWidget"
import "@common/components/Orders/OrderTableSortable"
import "@common/components/Partners/partnerListView"
import "@common/components/Car/CarData"
import "@common/components/Files/ImageListViewer"

import "@material/mwc-icon"
import "@material/mwc-textarea"
import "@material/mwc-textfield"
import "@material/mwc-tab-bar"
import "@material/mwc-tab"
import "@material/mwc-select"
import "@material/mwc-list"
import "@material/mwc-list/mwc-list-item"
import "@material/mwc-fab"

let alreadyCheckedFirebase = false

const initialize = async () => {
    
    // observer.observe(document.querySelector("#header-upper"))
    firebase.initializeApp({
        "apiKey": "AIzaSyBEWEHpDpfWdixmV4FN1TKUiAQd5RPVnnA",
        "appId": "1:368633701466:web:eabb4937864cd3a3872463",
        "authDomain": "bildoktoren-no.firebaseapp.com",
        "databaseURL": "https://bildoktoren-no.firebaseio.com",
        "measurementId": "G-VELVGLHK1K",
        "messagingSenderId": "368633701466",
        "projectId": "bildoktoren-no",
        "storageBucket": "bildoktoren-no.appspot.com"
    })
    
    //loadGoogleTag()
    //LoadHubspot()
    
    await langInit()
    const action = urlParser.getAction()
    const isEmailReset = (action && action.mode === "verifyEmail")
    firebase.auth().onAuthStateChanged(async (user) => {
        appInitialized.set(true)
        if (user) {
            console.log(`user ${user.email} logged in`)
            // const idToken = await auth.getIdToken()
            // document.cookie = "bearer" + "=" + idToken
            const up = await userauth.onuserIsLoggedIn(user)
            console.log(up)
            // if (!user.emailVerified && !isEmailReset) {
            //     user.sendEmailVerification().then(() => {
            //         console.log("confirmation mail sent")
            //     }).catch((err) => {
            //         console.error(err)
            //     });
            // }
        } else {
            userauth.setLoggedOutUserProfile()
        }
        if (!alreadyCheckedFirebase) {
            setInitialHref()
            alreadyCheckedFirebase = true
        }
    });
    if (action && action.mode) {
        console.log(`call action ${action.mode}`)
    }
}

export default { initialize }

import { materialIconsStyle } from "@common/style/stylesheets"

const styleEl = document.createElement("style")
const codeEl = document.createTextNode(materialIconsStyle)
styleEl.appendChild(codeEl)
document.head.appendChild(styleEl)
//document.adoptedStyleSheets = [materialIconsStyle]


const observerHandler = (entries: Array<IntersectionObserverEntry>) => {
    let entry = entries[0]
    //if (entry.boundingClientRect.y < 0) {
    if (entry.isIntersecting)
        document.body.classList.remove("header-not-at-top")
    else
        document.body.classList.add("header-not-at-top")
}
let observer = new IntersectionObserver(observerHandler)