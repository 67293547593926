/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ImageSizeType {
    Original = 0,
    OriginalCropped = 1,
    Thumbnail = 300,
    Small = 600,
    Medium = 1200,
    Big = 2000,
}