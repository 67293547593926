import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons"

@customElement('popdown-menu')
export class PopdownMenu extends LitElement {
    private _show: boolean = false
    
    static styles = css`
        :host {
            display: block;
            position: relative;
            background: var(--bd-secondary-background);
            --bd-fa-icon-width: 1.2rem;
            --bd-fa-icon-height: 1.2rem;
            --bd-button-height: 1.2rem;
        }
        fa-icon:hover {
            cursor: pointer;
        }
        div.popdown {
            display: flex;
            flex-direction: column;
            position: absolute;
            width: 200px;
            z-index: -100;
            top: var(--bd-button-height);
            right: 0;
            border-radius: var(--bd-border-radius, 8px);
            background: var(--bd-secondary-background);
            box-shadow: var(--bd-box-shadow-hover);
        }
        div.popdown.show {
            z-index: 1000;
        }
        div.top {
            display: flex;
            position: static;
            flex-direction: row;
            justify-content: flex-end;
        }
        div.content {
            position: static;
        }
        mwc-icon:hover {
            cursor: pointer;
        }
        
    `

    toggle = (e) => {
        if (e)
            e.stopPropagation();
        this._show = !this._show
        if (this._show)
            document.addEventListener("click", (e) => this.toggle(e), { once: true})
        this.requestUpdate()
    }

    render() {
        
        return html`
            <slot name="button" @click=${this.toggle}>
                <fa-icon .icon=${faEllipsisV}></fa-icon>
            </slot>
            
            <div class="popdown ${this._show ? 'show' : ''}">
                
                <div class="content">
                    <slot name="content">no content</slot>
                </div>
            </div>
        `
    }
}