import type { WebAction } from "@common/models/interfaces"

const getAction = (): WebAction => {
    if (window.location.pathname === "/emailAction") {
        const queryString = window.location.search
        console.log(queryString)
        const urlParams = new URLSearchParams(queryString)
        let ret = {}
        appendPar(ret, urlParams, "mode")
        appendPar(ret, urlParams, "oobCode")
        appendPar(ret, urlParams, "continueUrl")
        appendPar(ret, urlParams, "lang")
        return ret
    }
    return null
};
const appendPar = (ret, urlParams, name) => {
    if (urlParams.has(name)) {
        ret[name] = urlParams.get(name)
    }
}

export default {getAction}