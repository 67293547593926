import { SvelteSubject, clonedObservable } from "@common/store/reactive"
import restService from "@common/services/restService"
import geocoder from "@common/services/googleGeocoder"
import type { AuthUser, LocationLookup } from "@common/models/interfaces"
import type { UserViewModel } from "@api"

export const userIsLoggedIn = new SvelteSubject(false)
export const userAuthInfo = new SvelteSubject<AuthUser>({ loggedIn: false, name: "Anon", initials: "U", email: "..", emailVerified: false })

export class UserProfileSubject extends SvelteSubject<UserViewModel> {
    get isUnset() {
        return !this.value.id
    }
}
const notLoggedIn = {id:"", name: "", email: ""}

const userProfileSubject = new UserProfileSubject(notLoggedIn)
export const userProfile = clonedObservable(userProfileSubject)

const getUser = async () => {
    try {
        const user = await restService.get<UserViewModel>("userprofile")
        userProfileSubject.next(user)
    }
    catch(ex) { errorHandler(ex) }
};

export const update = async (user: UserViewModel) => {
    try {
        await tryUpdateLocation(user)
        await restService.put("userprofile", user)
        userProfileSubject.next(user)
    }
    catch(ex) { errorHandler(ex) }
};

export const refresh = () => getUser();

export const logOut = () => {
    userIsLoggedIn.set(false);
    userAuthInfo.set({ loggedIn: false, name: "Anon", uid: "", emailVerified: false });
}

const errorHandler = (ex) => console.log(ex);

userAuthInfo.subscribe(up => {
    if (up.loggedIn) {
        getUser()
    } else {
        userProfileSubject.next(notLoggedIn)
    }
})
const tryUpdateLocation = async (user: UserViewModel) => {
    let lookupTerm = `${user.address || ""} ${user.postalCode || ""} ${user.place || ""}`
    lookupTerm = lookupTerm.trim()
    if (!lookupTerm)
        return
    
    const res = await geocoder.geocodeAddress(lookupTerm)
    if (res.location) {
        user.location = res.location
    }
};