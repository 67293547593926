
import { getDepartmentByRoute } from "@common/store/departmentPublicStore"

import routes from "./routes"
import NotFound from "@app/views/404.svelte"

import pathBreaker from "@common/funcs/pathBreaker"

import { userAuthInfo, userIsLoggedIn } from "@common/store/userProfile"
// import { playIntro } from "@app/store"
import type { Route } from "@common/models/interfaces"
import { gotoPath, curRoutePath, setCurRoute } from "@common/routing/router"

const findRoute = async (fullpath): Promise<Route> => {
    const bRoute = pathBreaker.getRoute(fullpath)
    
    const dep = getDepartmentByRoute(bRoute.path)
    if (dep) {
        const rDep = routes.filter(r => r.path === "dep")[0]
        return {
            path: rDep.path,
            component: rDep.component,
            param: dep.id,
            title: dep.name
        }
    }

    const route = routes.filter(r => r.path === bRoute.path)
    
    const nf404 = notFound(fullpath)
    
    if (!route || route.length === 0) {
        gotoPath("/")
        return
    }
        
    const r = route[0]

    const up = userAuthInfo.get()
    const loggedIn = userIsLoggedIn.get()
    if (r.mustBeLoggedIn && !loggedIn) {
        localStorage.setItem("postLoginPath", fullpath)
        gotoPath("/signin")
        return
    }

    if (r.admin && !up.isAdmin)
        return nf404

    if (r.init) {
        r.init(bRoute.param)
    }
    return {
        path: r.path,
        component: r.component,
        param: bRoute.param,
        action: bRoute.action,
        title: r.title,
        search: bRoute.search
    }
}

const notFound = (path): Route => {
    return {
        path, 
        component: NotFound
    }
}

curRoutePath.subscribe(async path => {
    const route = await findRoute(path)
    setCurRoute(route)
})