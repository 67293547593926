import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"
import {unsafeHTML} from "lit/directives/unsafe-html.js"

@customElement('html-preview')
export class BlogPreview extends LitElement {
    private limitChars = 350
    static styles = css`
        :host {
            display: inline-block
        }
    `
    @property({attribute: false})
    html: string
    
    getPreview() {
        let dummy = document.createElement("div")
        let totalChars = 0
        let returnHtml = ""
        dummy.innerHTML = this.html
        if (dummy.hasChildNodes()) {
            const children = dummy.childNodes
            for (let i = 0; i < children.length; i++) {
                const child: ChildNode = children[i]
                const content = (child as HTMLElement).innerHTML
                if ((totalChars + content.length) > this.limitChars)
                    return returnHtml
                totalChars += content.length
                returnHtml += (child as HTMLElement).outerHTML
            }
        }
        return returnHtml
    }
    render() {
        if (!this.html)
            return html`<span class="no-content"></span>`
        
        const preview = this.getPreview()
        return html`${unsafeHTML(preview)}`
    }
}