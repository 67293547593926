<script lang="ts">
    import { onMount, onDestroy } from "svelte"
    import { orderConfirmationStore } from "@app/store/orderConfirmationStore"
    import type { Subscription } from "rxjs"
    import type { OrderViewModel } from "@api"

    let sub: Subscription
    let order: OrderViewModel = {id:""}
    onMount(() => {
        sub = orderConfirmationStore.subscribe((value) => {
            order = value
        })
    })
    
    onDestroy(() => sub.unsubscribe())
</script>

<article class="default">
    <header class="header white-bg">
        <h1>Bestilling bekreftet</h1>
    </header>
    {#if order.lines}
        <section class="white-bg">
            
            {#each order.lines as line}
                <div class="line">
                    <div class="desc">
                        {line.productDescription} - {line.variantDescription} {line.price}
                    </div>
                </div>
                
            {/each}

        </section>
        <div class="white-bg comment">
            {order.noteFromBuyer}
        </div>
        <!-- <div id="klarna-widget-snippet">
            {@html order.klarnaOrder.html_snippet}
        </div> -->
        <!-- <KlarnaPayment /> -->
    {:else}
        Venter på bekreftelse
    {/if}
</article>