<script lang="ts">
    import { onMount } from "svelte"
    import urlParser from "@common/funcs/urlParser"
    import auth from "@common/services/authentication"
    import lang from "@common/services/lang"
    import Link from "@common/components/Nav/Link.svelte"

    let actionText = lang("action")
    let action = null
    let done = false

    let successMsg = "", errorMsg = ""
    let showResetPassword = false
    let email = ""

    let showResetPasswordLink = false

    let actionCode = ""
    let newPassword = "", newPassword2 = ""
    let resetPassword = async () => {
        errorMsg = ""
        if (newPassword !== newPassword2) {
            errorMsg = lang("password-must-be-equal");
            return;
        }
        try {
            await auth.confirmPasswordReset(actionCode, newPassword)
            successMsg = lang("password-updated")
            newPassword = ""
            newPassword2 = ""
            showResetPassword = false
            done = true
        }
        catch(err) {
            console.log(err)
            errorMsg = lang(err.code)
        }
    }

    const sendForgotPassword = async () => {
        try {
            const res = await auth.sendForgotPasswordMail(email)
            showResetPasswordLink = false
            successMsg = lang("forgot-password-link-sent")
            console.log(res)
        } catch(err) {
            console.error(err)
            errorMsg = lang(err.code)
        }
    }

    onMount(async () => {
        action = urlParser.getAction()
        if (action && action.mode) {
            actionText = lang(action.mode)
            if (action.mode === "verifyEmail") {
                try {
                    const res = await auth.applyActionCode(action.oobCode)
                    console.log(res)
                    successMsg = lang("email-verified")
                    done = true
                } catch(ex) {
                    console.log(`${ex.code} - ${ex.message}`)
                    errorMsg = lang(ex.code)
                }
            }
            else if (action.mode === "recoverEmail") {
                const info = await auth.checkActionCode(action.oobCode)
                console.log(JSON.stringify(info))
                email = info["data"]["email"]
                successMsg = `${lang("email-restored")}: ${email}`
                showResetPasswordLink = true
                done = true
            }
            else if (action.mode === "resetPassword") {
                actionCode = action.oobCode
                email = await auth.verifyPasswordResetCode(actionCode)
                showResetPassword = true
            }
        }
        
    })
</script>
<style>
    .success {
        color: var(--success);
    }
    .error {
        color: var(--error);
    }
    #proceed-message {
        display: none;
    }
    #proceed-message.done {
        display: block;
    }
    #reset-password-link {
        display: none;
    }
    #reset-password-link {
        display: none;
    }
    form {
        display: none;
        flex-direction: column;
        justify-content: space-between;
        text-align: center;
        align-items: center
    }
    form.show {
        display: flex;
    }
</style>
<!--
    emailAction?mode=verifyEmail&oobCode=cb7RF8WDbEipoQB6Stpx9fBNV-ilRgzagNHGELQ0pDwAAAF3AV4slQ&apiKey=AIzaSyBEWEHpDpfWdixmV4FN1TKUiAQd5RPVnnA&lang=no
    emailAction?mode=resetPassword&oobCode=vyJHKYnqH2T6YugHFk8zKUwELnZI4M87i5c2Db3YVtIAAAF3Bo6TtQ&apiKey=AIzaSyBEWEHpDpfWdixmV4FN1TKUiAQd5RPVnnA&lang=no
-->
<article class="default center">
    <header>
        <h3>{actionText}</h3>
    </header>
    <section>
        <h4 class="success">
            {successMsg}
        </h4>
        <h4 class="error">
            {errorMsg}
        </h4>
        <form class:show={showResetPassword}>
            <input type="password" bind:value={newPassword} placeholder="{lang('password')}" /><br>
            <input type="password" bind:value={newPassword2} placeholder="{lang('confirm-password')}" /><br>
            <button on:click|preventDefault="{resetPassword}">{lang('change-password')}</button>
        </form>

        <div id="reset-password-link" class:show={showResetPasswordLink}>
            <a href="/#" on:click|preventDefault={sendForgotPassword}>{lang('forgot-password')}</a>
        </div>

        <div id="proceed-message" class:done>
            <Link route="/" langCode="home">
                <figure class="loading-logo">
                    <img src="https://storage.googleapis.com/bildoktoren-no.appspot.com/assets/logos/bd-logo-white-inside-300-fs8.png" alt="bildoktoren logo">
                    <figcaption></figcaption>
                </figure>
            </Link>
        </div>
    </section>
</article>