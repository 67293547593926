import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"
import {unsafeHTML} from "lit/directives/unsafe-html.js"
// import { goto } from "@common/routing/router"
import type { BlogPostViewModel } from "@api"
import { goto } from "@common/routing/router"

@customElement('blog-preview')
export class BlogPreview extends LitElement {
    
    static styles = css`
        :host {
            display: inline-flex;
            min-width: 300px;
            width: 380px;
            max-width: 380px;
            flex-direction: column;
            text-align: center;
            border-radius: 5px;
            transition: background .6s ease;
            box-sizing: border-box;
            border: 1px solid #f1f1f1;
            box-shadow: 0 0 20px -18px;
            padding: 0.5rem;
        }
        h1 {
            font-size: 18px;
        }
        a {
            text-decoration: none;
            /* white-space: nowrap; */
            color: var(--mdc-theme-text-primary-on-background);
        }
        @media only screen and (max-width: 640px) {
            :host {
                min-width: 100%;
                width: 100%;
                max-width: 100%;
            }
        }
    `
    @property({attribute: false})
    post: BlogPostViewModel;
    
    render() {
        let preview = this.post.preview;
        if (preview.length>200)
            preview = `${preview.substr(0,200)} ...`
        return html`<a href="/blog/${this.post.routeKey}" @click=${goto}>
                <h1>${this.post.headline}</h1>
                ${unsafeHTML(preview)}
            </a>
        `
    }
}