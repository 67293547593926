let system = ""
const apiurl = process.env.API_URL //import.meta.env.SNOWPACK_PUBLIC_API_URL
const prodDeploy = process.env.PROD_DEPLOY

let subscriptionClubPriceId = "price_1KT0AgFCVvbpEYXNCVOBHvss"

if (apiurl.includes("localhost")) {
    system = "localhost"
    subscriptionClubPriceId = ""
}
if (apiurl.includes("test.api")) {
    system = "test"
    subscriptionClubPriceId = "price_1KbUyBFCVvbpEYXNxLzFxncw"
}

let webUrl = "https://app.bildoktoren.no"
if (system == "test")
    webUrl = "https://test.bildoktoren.no"



export const staticPath = "https://about.bildoktoren.no"
//const staticPath = "http://localhost:8001"
export default { apiurl, system, prodDeploy, webUrl, subscriptionClubPriceId }