/// <reference types="google.maps" />

import type { LocationLookup } from "@common/models/interfaces"

let geocoder
const init = () => {
    geocoder = new google.maps.Geocoder()
}

const geocodeAddress = (address): Promise<LocationLookup> => {
    return new Promise((resolve, reject) => {
        geocoder.geocode({ address: address }, (results, status) => {
            if (status === "OK") {
                const res = results[0]
                const placeId = res.place_id
                const lng = res.geometry.location.lng()
                const lat = res.geometry.location.lat()
                const location = { lat, lng }
                resolve({location, placeId })
            } else {
                reject("Geocode was not successful for the following reason: " + status)
            }
        })
    })
}

init()

export default { geocodeAddress }