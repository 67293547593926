<script lang="ts">
    import type { DiagnoseViewModel } from "@api"
    import { ImageSizeType } from "@api"
    import { gotoPath } from "@common/routing/router"
    import FileLink from "@common/components/Files/FileLink.svelte"

    export let diagnose: DiagnoseViewModel
    
</script>
<style>
    .list {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        flex-wrap: wrap;
    }
    .image-wrapper {
        cursor: pointer;
    }
    .attachments {
        text-align: left;
        margin-bottom: 1rem;
    }
</style>


<div class="attachments">
    {#if diagnose.attachments}
        {#each diagnose.attachments as attachment}
            <FileLink file={attachment} />
        {/each}
    {/if}
</div>

<div class="list">
    {#if diagnose.pictures }
        {#each diagnose.pictures as image, i}
            <div class="image-wrapper">
                <image-viewer class="thumbnail" 
                    image={image} 
                    wantedsize={ImageSizeType.Thumbnail} 
                    alt="main" on:click={() => gotoPath(`/diagnoses/${diagnose.id}/images?num=${i}`)}></image-viewer>
            </div>
        {/each}
    {:else}
        <p>ingen bilder</p>
    {/if}
</div>
