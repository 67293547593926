import restService from "@common/services/restService"
import lang from "@common/services/lang"
import toast from "@common/services/toastService"
import { SvelteSubject, clonedObservable } from "@common/store/reactive"
import type { DiagnosePostModel, DiagnoseViewModel, DocumentState } from "@api"
import { userAuthInfo } from "@common/store/userProfile"
import { map } from "rxjs"

const url = "diagnose"

const diagnosesSubject = new SvelteSubject<DiagnoseViewModel[]>([])
export const diagnoses = clonedObservable(diagnosesSubject)

const fetchMine = async () => {
    try {
        const diagnoses = await restService.get<DiagnoseViewModel[]>(url)
        diagnosesSubject.next(diagnoses)
    } catch (e) {
        console.log(e)
    }
}

export const lastFiveDiagnoses = diagnosesSubject.pipe(
    map(data => {
        if (!data)
            return []
        const sliceVal = Math.min(5, data.length)
        return data.slice(0, sliceVal)
    })
)

export const fetchAllAdmin = async () => {
    const all = await restService.get<DiagnoseViewModel[]>(`${url}/listadmin`)
    return all
}

userAuthInfo.subscribe(user => {
    if (user.loggedIn)
        fetchMine()
    else
        diagnosesSubject.next([])
})

export const createDiagnose = async (newDiagnose: DiagnosePostModel) => {
    const diagnose = await restService.post<DiagnoseViewModel>(url, newDiagnose)
    toast.success(`${lang("diagnose")} ${lang("created")}`)
    fetchMine()
    return diagnose
}

export const getDiagnose = (id: string) => {
    const currentDiagnoses = diagnosesSubject.get()
    let diagnose = currentDiagnoses.find(d => d.id === id)
    return diagnose
}


export const getDiagnoseDetails = async (id: string) => {
    const dep = await restService.get<DiagnoseViewModel>(`${url}/${id}`)
    if (!dep.description)
        dep.description = { html: "", json: ""}
    return dep
}

export const updateDiagnose = async (diagnose: DiagnosePostModel) => {
    const updated = await restService.put<DiagnoseViewModel>(url, diagnose)
    toast.success(`${lang("diagnose")} ${lang("saved")}`)
    fetchMine()
    return updated
}

export const setDiagnoseState = async (id: string, state: DocumentState) => {
    const sm = { state }
    const updated = await restService.put<DiagnoseViewModel>(`${url}/setstate/${id}`, sm)
    fetchAllAdmin()
    return updated
}