import type { Route } from "@common/models/interfaces"
import { SvelteSubject } from "@common/store/reactive"
const curRoutePathSubject = new SvelteSubject<string>("")
export const curRoutePath = curRoutePathSubject.asObservable()

const curRouteSubject = new SvelteSubject<Route>({ path: "/", component: null})
export const setCurRoute = (route: Route) => {
    if (route && route.path)
        curRouteSubject.next(route)
}
export const curRoute = curRouteSubject.asObservable()

export const getCurRoute = () => {
    const cur = curRoutePathSubject.getValue()
    return cur
}
export const goto = (e) => {
    e.preventDefault()
    if (e.currentTarget && e.currentTarget.pathname) {
        const path = e.currentTarget.pathname
        curRoutePathSubject.next(path)
    }
}

export const gotoPath = (path) => {
    if (path)
        curRoutePathSubject.next(path)
}

window.addEventListener("popstate", (event: PopStateEvent) => {
    gotoPath(event.state.path)
})

const pushPathToHistory = (path) => {
    if (!path)
        return;
    if (window.location.pathname != path)
        window.history.pushState({ path }, "", window.location.origin + path)
}

curRoutePath.subscribe(pushPathToHistory)

export const setInitialHref = () => {
    gotoPath(`${window.location.pathname}${window.location.search}`)
}

export const setSearchParam = (name: string, val: string) => {
    let curr = new URLSearchParams(window.location.search)
    if (curr.has(name))
        curr.set(name, val)
    else
        curr.append(name, val)
    const newSearch = curr.toString()
    const newHref = `${window.location.pathname}?${newSearch}`
    gotoPath(newHref)
}