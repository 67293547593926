<script lang="ts">
    import { userIsLoggedIn } from "@common/store/userProfile"
    import lang from "@common/services/lang"
    import { uiLoginGoogle, uiLoginFb, uiRegisterUserEmail } from "@common/services/loginInterfaceService"
    import GoogleLoginButton from "@common/components/Logos/GoogleLoginButton.svelte"
    // import FacebookLoginButton from "@common/components/Logos/FacebookLoginButton.svelte"

    let email:string, pass: string
    let msg = ""

    const loginGoogle = async (e: Event) => {
        e.preventDefault()
        msg = await uiLoginGoogle()
    }
    const loginFb = async (e: Event) => {
        e.preventDefault()
        msg = await uiLoginFb()
    }

    const regEmail = async (e: Event) => {
        e.preventDefault()
        msg = await uiRegisterUserEmail(email, pass)
    }
</script>

<style>
    article {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    input {
        flex: 1;
        width: 299px;
        margin-bottom: 0.4rem;
    }
    button {
        width: 299px;
    }
</style>
<article>
    {#if $userIsLoggedIn}
        <p>{lang('logged-in')}</p>
    {:else}
        <div>
            <h1>Registrer deg</h1>
            <p>
                <GoogleLoginButton on:click={loginGoogle} />
                <br><br>
                <!-- <FacebookLoginButton on:click={loginFb} /> -->
            </p>
            <p>
                Eller du kan opprette en vanlig bruker med epost og passord
            </p>
            <form>
                <input id="create-email" type="text" bind:value="{email}" placeholder="{lang('email')}" />
                <input id="create-password" type="password" bind:value="{pass}" placeholder="{lang('password')}" />
                <button on:click="{regEmail}">{lang("register")}</button>
            </form>

            <p>{msg}</p>
        </div>
    {/if}
</article>
