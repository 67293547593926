import {LitElement, html, css} from "lit"
import {customElement} from "lit/decorators.js"

@customElement('sign-widget')
export class DirectionSign extends LitElement {
    
    static styles = css`
        :host {
            --bd-sign-height: 30px;
            --bd-sign-background: var(--mdc-theme-primary);
            --bd-sign-color: #fff;
            display: inline-block;
            /* background: var(--bd-sign-color); */
            font-family: 'Libre Franklin', sans-serif;
            display: inline-flex;
            flex-wrap: nowrap;
            padding: 2px;
            transition: background .1s ease;
        }
        .sign-tag {
            border-radius: 5px;
            border: 3px solid var(--bd-sign-color);
            background: var(--bd-sign-background);
            font-weight: 900;
            color: var(--bd-sign-color);
            cursor: pointer;
            display: inline-block;
            font-size: 1rem;
            height: var(--bd-sign-height);
            line-height: var(--bd-sign-height);
            padding: 0 1rem;
            text-align: center;
        }
        slot {
            white-space: nowrap;
        }
    `;
    
    render() {
        return html`        
            <div class="sign-tag">
                <slot></slot>
            </div>
        `
    }
}