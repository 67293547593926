import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"
import type { CarData } from "@api"

@customElement('car-data')
export class CarDataElement extends LitElement {

    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            padding: 0.1rem 0.5rem;
            gap: 3rem;
            border: 1px solid grey;
        }
    `
    @property({attribute: false})
    car: CarData = null
    
    render() {
        if (!this.car)
            return html`<div>ingen data</div>`
        
        return html`<div>
            <p>
                <label>Produsent / modell:</label>
                <span>${this.car.manufacturer}</span>-
                <span>${this.car.model}</span>
            </p>
            <p>
                <label>Førstegangsregistrert:</label>
                <date-viewer .date=${this.car.regDate}></date-viewer>
            </p>
            <p>
                <label>EU godkjenning</label>
                <small>Sist</small>: <date-viewer .date=${this.car.euApprovalLast}></date-viewer>
                <small>Frist</small>: <date-viewer .date=${this.car.euApprovalDeadline}></date-viewer>
            </p>
            <p>
                <label>Motor:</label>
                <span>${this.car.engine}</span>
                <label>Drivstoff:</label>
                <span>${this.car.fuel}</span>
            </p>
        </div>`
    }
}