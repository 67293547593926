import Home from "@app/views/Index.svelte"
import SignIn from "@common/views/SignIn.svelte"
import SignUp from "@common/views/SignUp.svelte"
import Account from "@common/views/account/Index.svelte"
import Confirmation from "@app/views/Confirmation.svelte"
import Auth from "@app/views/Auth.svelte"
import EmailAction from "@common/views/EmailAction.svelte"

// import Partners from "@app/views/partners/Index.svelte"
// import Blog from "@app/views/blog/Index.svelte"
// import About from "@app/views/About.svelte"
// import Contact from "@app/views/Contact.svelte"
// import Locations from "@app/views/Locations.svelte"
// import DepartmentDetails from "@app/views/departments/DepartmentDetails.svelte"
// import Services from "@app/views/products/Index.svelte"
// import How from "@app/views/How.svelte"
// import Terms from "@app/views/Terms.svelte"
// import Privacy from "@app/views/Privacy.svelte"
//import Cart from "@app/views/Cart.svelte"
import Membership from "@app/views/Membership.svelte"
import Success from '@app/views/Success.svelte'
import Cancel from '@app/views/Cancel.svelte'
import MembershipLog from "@app/views/MembershipLog.svelte"

import Diagnose from "@app/views/diagnose/Index.svelte"

import type { Route } from "@common/models/interfaces"

const routes: Route[] =  [
    {
        path: "/",
        component: Home,
    },
    {
        path: "account",
        component: Account,
        title: "Konto",
        mustBeLoggedIn: true
    },
    {
        path: "signin",
        component: SignIn,
        title: "sign in"
    },
    {
        path: "signup",
        component: SignUp,
        title: "sign up"
    },
    {
        path: "auth",
        component: Auth
    },
    {
        path: "emailAction",
        component: EmailAction,
        title: "epost"
    },
    {
        path: "confirmation",
        component: Confirmation,
        title: "Bekreftelse"
    },
    {
        path: "diagnoses",
        component: Diagnose,
        title: "Diagnose"
    },
    {
        path: "membership",
        component: Membership,
        title: "Medlemskap",
        mustBeLoggedIn: true
    },
    {
        path: "success",
        component: Success,
        title: "Suksess"
    },
    {
        path: "cancel",
        component: Cancel,
        title: "Kansellert"
    },
    {
        path: "membershiplog",
        component: MembershipLog,
        mustBeLoggedIn: true
    }
    // {
    //     path: "blog",
    //     component: Blog,
    //     title: "blogg"
    // },
    // {
    //     path: "contact",
    //     component: Contact,
    //     title: "kontakt oss"
    // },
    // {
    //     path: "about",
    //     component: About,
    //     title: "om oss"
    // },
    // {
    //     path: "terms",
    //     component: Terms,
    //     title: "Salgsbetingelser"
    // },
    // {
    //     path: "privacy",
    //     component: Privacy,
    //     title: "Personvern"
    // },
    // {
    //     path: "locations",
    //     component: Locations,
    //     title: "lokasjoner"
    // },
    // {
    //     path: "dep",
    //     component: DepartmentDetails,
    //     title: "avdeling"
    // },
    // {
    //     path: "services",
    //     component: Services,
    //     title: "tjenester"
    // },
    // {
    //     path: "how",
    //     component: How,
    //     title: "hvordan vi kan hjelpe"
    // },
    // {
    //     path: "cart",
    //     component: Cart,
    //     title: "Bestilling"
    // },
    // {
    //     path: "partners",
    //     component: Partners,
    //     title: "Verksteder"
    // },
    
]
export default routes