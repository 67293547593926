<script lang="ts">
    import {onMount} from "svelte"
    import type { UserPaymentLogDb } from "@api"
    import rest from "@common/services/restService"
    import lang from "@common/services/lang"

    const urlLogs = "paymentApi/logsuser"
    let logs: UserPaymentLogDb[] = []
    const getLogs = async () => {
        logs = await rest.get<UserPaymentLogDb[]>(urlLogs)
    }
    onMount(() => {
        getLogs()
    })
</script>

<article class="default">
    <header>
        <h3>Kjøpshistorikk</h3>
    </header>
    <div>
        {#each logs as log}
            <p>
                {lang(log.event)} - <date-time-viewer date={log.created}></date-time-viewer>
                {#if log.stateDetails?.cancelAt}
                    <span>Dato for kansellering satt til </span>
                    <date-time-viewer date={log.stateDetails.cancelAt}></date-time-viewer>
                {/if}
            </p>
        {/each}
    </div>
</article>