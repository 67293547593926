/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ContentStaticIdentifier {
    HomepageBanner = 0,
    AboutPage = 1,
    ContactPage = 2,
    TermsPage = 3,
    PrivacyPage = 4,
    HowPage = 5,
    MembersPage = 6,
}