import {LitElement, html, css } from "lit"
import {customElement, property} from "lit/decorators.js"
import type { Link } from "@api"

@customElement('product-link-list')
export class ProductLinkList extends LitElement {
    static styles = css`
        :host {
            display: flex;
            gap: 2rem;
        }
    `;
    
    @property({attribute: false}) 
    links: Link[]

    render() {
        if (!this.links || this.links.length == 0)
            return html`
                <p>Ingen produkt</p>
            `
        return this.links.map(l => {
            return html`
                <product-link .link=${l}></product-link>
            `
        })
    }
}