<script>
    import { userIsLoggedIn, userAuthInfo } from "@common/store/userProfile"
    import lang from "@common/services/lang"
    import auth from "@common/services/authentication"
    import toast from "@common/services/toastService"
    let password = "", password2 = ""
    let errorMsg = ""
    //let validProviders = ["google.com", "facebook.com", "password"];
    userAuthInfo.value.providerData[0].providerId
    let updatePassword = async () => {
        errorMsg = ""
        if (password !== password2) {
            errorMsg = lang("password-must-be-equal")
            return;
        }
        try {
            await auth.changePassword(password)
            errorMsg = lang("password-updated")
            password = ""
            password2 = ""
        }
        catch(err) {
            console.log(err)
            errorMsg = lang(err.code)
        }
    }

    let sendEmailVerification = async () => {
        await auth.sendEmailVerification()
        toast.success("Epost for verifisering sendt!")
    }
</script>
<style>
    input {
        margin-bottom: 0.5rem;
    }
    span#idprovider {
        font-weight: bolder;
    }
    span#email {
        font-weight: bold;
    }
    .link-button {
        margin-top: 2rem;
    }

</style>
{#if $userIsLoggedIn}
    {#if $userAuthInfo.photoURL}
        <img src="{$userAuthInfo.photoURL}" alt="user" />
    {/if}
    <p class="primary-on-background">
        <span>{lang('logged-in')} {lang('email')}</span>&nbsp;
        <span id="email">{$userAuthInfo.email}</span>
        {#if $userAuthInfo.emailVerified}
            <span>(verifisert)</span>
        {:else}
            <span>(ikke verifisert)</span>
            <button on:click|preventDefault={sendEmailVerification}>Send epost for verifisering</button>
        {/if}
    </p>
    
    {#if $userAuthInfo.providerData.some(p => p.providerId === "password")}
        <p>{lang('change-password')}</p>
        <form>
            <input type="password" bind:value={password} placeholder="{lang('password')}" /><br>
            <input type="password" bind:value={password2} placeholder="{lang('confirm-password')}" /><br>
            <button on:click|preventDefault={updatePassword}>{lang('save')}</button>
        </form>
        <div>{errorMsg}</div>

    {/if}

    {#if ($userAuthInfo.providerData.some(p => p.providerId === "google.com"))}
        <p class="primary-on-background">
            <span>{lang('account-linked-with')}</span>&nbsp;
            <span id="idprovider">Google</span>
        </p>
    {:else}
        <button class="link-button" on:click={() => auth.linkWithGoogle()}>Link konto med Google</button>
    {/if}
    
    
{/if}