<script lang="ts">
    import { onMount, onDestroy } from "svelte"
    import type { Subscription } from "rxjs"
    import { openMainMenu } from "@common/store"
	import { tweened } from "svelte/motion"
	import { cubicOut } from "svelte/easing"
    import TouchGestures from "@common/services/touchGestures"

    let touch:TouchGestures
    let menuEl: HTMLElement
    let sub: Subscription
    let open = false

    const slideLength = 300
    let right = 0
    onMount(() => {
        setRight()
         touch = new TouchGestures(menuEl, () => console.log("none"), () => openMainMenu.set(false) )
        sub = openMainMenu.subscribe(value => {
            if (value) {
                slideIn()
                document.addEventListener("click", closeMenu)
            }
            else {
                slideOut()
                document.removeEventListener("click", closeMenu);
            }
        });
    })
    onDestroy(() => {
        sub.unsubscribe()
        touch.removeEvents()
    })

    const setRight = () => {
        let docWidth = document.body.offsetWidth
        let mainEl = document.querySelector("main")
        let mainElWidth = mainEl.offsetWidth
        right = (docWidth - mainElWidth) / 2
    }
	const menuRight = tweened(-slideLength, {
		duration: 400,
		easing: cubicOut,
	})
	const slideIn = () => {
		menuRight.set(0)
        open = true
	}
	const slideOut = () => {
		menuRight.set(-slideLength)
        open = false
	}
    const closeMenu = (e: Event) => {
        e.preventDefault()
        e.stopPropagation()
        setRight()
        const target = e.target as HTMLElement
        if ($menuRight == 0 && menuEl && e.target && !menuEl.contains(target)) {
            openMainMenu.set(false)
        }
    }
    
</script>

<style>
	* {
		box-sizing: border-box;
		color: #fff;
	}
    div.wrapper {
        position: fixed;
        z-index: -100;
        right: -100vw;
        top: 0;
        width: 100vw;
        max-width: 100vw;
        height: 100vh;
        overflow: auto;
        background-color: transparent;
        transition: background-color .2s linear;
    }
    div.wrapper.open {
        left: 0;
        z-index: 1100;
    }
    div.wrapper.modal {
        background-color: rgba(0,0,0,0.4);
    }
	div.menu {
        opacity: 0;
        transition: opacity .1s linear;
		background: var(--mdc-theme-background);
        max-width: 100vw;
		height: 100vh;
		position: fixed;
		top:0;
		padding: 0;
		z-index: 1;
	}
    div.menu {
        display: flex;
        flex-direction: column;
    }
    div.menu.open {
        opacity: 1;
    }
    #menu-upper {
		color: var(--mdc-theme-text-primary-on-dark);
		display: flex;
		flex-direction: row-reverse;
		padding: 0.5rem;
		box-sizing: border-box;
	}
	.material-icons.menu {
		font-size: 3rem;
		color: var(--mdc-theme-primary);
		cursor: pointer;
	}
</style>

<div class="wrapper" class:open={$menuRight > -slideLength} 
    class:modal={open}>
    <div class="menu" class:open={$menuRight > -slideLength/2}
        style="width: {slideLength}px; right: {$menuRight + right}px" 
        bind:this={menuEl}>
        <section id="menu-upper">
            <span class="material-icons menu" on:click={() => openMainMenu.set(false)}>
                close
            </span>
        </section>
        <slot></slot>
    </div>
</div>
