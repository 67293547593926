import { KeyNav } from '@common/models/interfaces';
import type {ReactiveControllerHost} from 'lit'

export class KeyboardController {
    private host: ReactiveControllerHost;
    
    NavFunction(key: KeyNav) {
        console.log(key)
    }

    _onKeyDown = (e: KeyboardEvent) => {
        
        switch (e.code) {
            case KeyNav.Left:
                this.NavFunction(KeyNav.Left)
                break
            case KeyNav.Up:
                this.NavFunction(KeyNav.Up)
                break
            case KeyNav.Right:
                this.NavFunction(KeyNav.Right)
                break;
            case KeyNav.Down:
                this.NavFunction(KeyNav.Down)
                break
        }
    }

    constructor(host: ReactiveControllerHost) {
        this.host = host
        host.addController(this)
    }

    hostConnected() {
        document.addEventListener('keydown', this._onKeyDown);
    }

    hostDisconnected() {
        document.removeEventListener('keydown', this._onKeyDown)
    }
}