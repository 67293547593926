import {LitElement, html, css } from "lit"
import {customElement, property} from "lit/decorators.js"
import type { Link } from "@api"

@customElement('product-link')
export class ProductLink extends LitElement {
    private _link: Link
    static styles = css`
        :host {
            display: inline-flex
        }
    `
    
    set link(value: Link) {
        this._link = value
        this.requestUpdate()
    }
    get link(): Link {
        return this._link
    }
    render() {
        if (!this.link)
            return html`
                <p>N/A</p>
            `
        
        return html`
            <p>
                <a href="${this.link.url}">${this.link.description}</a>
            </p>
        `
    }
}