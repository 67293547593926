import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"
import type { BlogPostViewModel } from "@api"
import { goto } from "@common/routing/router"

@customElement('blog-widget')
export class BlogWidget extends LitElement {
    
    static styles = css`
        :host {
            display: flex;
            flex-direction: row;
            gap: 1rem;
        }
        h1 {
            font-size: 18px;
        }
        a {
            text-decoration: none;
            /* white-space: nowrap; */
            color: var(--mdc-theme-text-primary-on-background);
        }
        .part {
            flex: 1 1 0;
        }
        @media only screen and (max-width: 640px) {
            :host {
                min-width: 100%;
                width: 100%;
                max-width: 100%;
            }
        }
    `
    @property({attribute: false})
    post: BlogPostViewModel;
    
    @property({attribute: false})
    imgleft: boolean

    renderLink(link, external) {
        return external ? html`
            <a href=${link} target="_blank">
                <h1>${this.post.headline}</h1>
                <html-preview .html=${this.post.body.html}></html-preview>
            </a>
        ` : html`
            <a href=${link} @click=${goto}>
                <h1>${this.post.headline}</h1>
                <html-preview .html=${this.post.body.html}></html-preview>
            </a>
        `
    }
    render() {
        let link = `/blog/${this.post.routeKey}`
        let externalLink = false
        if (this.post.externalLink) {
            externalLink = true
            link = this.post.externalLink
        }
            
        if (!this.post.assetId || this.post.assetId == "00000000-0000-0000-0000-000000000000") {
            return html`
                <div class="text part">
                    ${this.renderLink(link, externalLink)}
                </div>
            `
        }
        if (!this.imgleft) {
            return html`
                <div class="text part">
                    ${this.renderLink(link, externalLink)}
                </div>
                <div class="image part">
                    <asset-image-viewer id=${this.post.assetId}></asset-image-viewer>
                </div>
            `
        }
        return html`
            
            <div class="image part">
                <asset-image-viewer id=${this.post.assetId}></asset-image-viewer>
            </div>
            <div class="text part">
                ${this.renderLink(link, externalLink)}
            </div>
            
        `
    }
}