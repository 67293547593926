import {LitElement, html, css, unsafeCSS} from "lit"
import {customElement} from "lit/decorators.js"
import {repeat} from "lit/directives/repeat.js"

import { goto } from "@common/routing/router"
import lang from "@common/services/lang"
import { materialIconsStyle } from "@common/style/stylesheets"
import SortFuncs from "@common/funcs/sortFuncs"
import type { OrderListModel } from "@api"

@customElement('order-table-sortable')
export class OrderTableSortable extends LitElement {
    _orders: OrderListModel[]
    _dateLabel = lang("date")
    _locationLabel = lang("location")
    _statusLabel = lang("status")

    _sortFunc = new SortFuncs()
    static styles = css`
        :host {
            display: flex;
            color: var(--mdc-theme-text-primary-on-background);
        }
        .sortable {
            cursor: pointer;
        }
        ${unsafeCSS(materialIconsStyle)}
    `;

    constructor() {
        super();
    }
    
    private sort(column: string) {
        let sortFn = this._sortFunc.getSortFn(this._orders, column);
        this.sortByFn(sortFn);
    }
    private sortByFn(fn) {
        let oldVal = Array.from(this._orders);
        let sorted = this._orders.sort(fn);
        this._orders = sorted;
        this.requestUpdate("users", oldVal);
    }
    
    set orders(val: OrderListModel[]) {
        let oldVal = this._orders;
        this._orders = val;
        this.requestUpdate("orders", oldVal)
    }

    get orders(): OrderListModel[] {
        return this._orders;
    }

    render() {
        return html`
        <table>
            <thead>
                <th class="sortable" @click="${() => this.sort('created')}">${this._dateLabel}</th>
                <th class="sortable" @click="${() => this.sort('location')}">${this._locationLabel}</th>
                <th class="sortable" @click="${() => this.sort('orderNumber')}">Ordre Nr</th>
                <th class="sortable" @click="${() => this.sort('status')}">${this._statusLabel}</th>
            </thead>
            <tbody>
            
                ${repeat(this.orders, (o) => o.id, (o, index) => html`
                    <tr>
                        
                        <td>
                            <a href="/orders/${o.id}" @click=${goto}>
                                <date-viewer .date=${o.created}></date-viewer>
                            </a>
                        </td>
                        <td>
                            ${o.location}
                        </td>
                        <td>
                            ${o.orderNumber}
                        </td>
                        <td>
                            ${lang(o.status)}
                        </td>
                    </tr>
                `)}
            
            </tbody>
        </table>
        `;
    }
}