<script lang="ts">
    import type { StorageFileViewModel } from "@api";
    export let file: StorageFileViewModel = null;
</script>
<style>
    span {
        color: var(--mdc-theme-text-primary-on-background);
    }
</style>

<div>
    {#if file.extension.toLowerCase() === "pdf"}
        <span class="material-icons">picture_as_pdf</span>
    {:else}
        <span class="material-icons">file_copy</span>
    {/if}
    <a href="{file.downloadUrl}" target="_blank">
        {file.name}.{file.extension}
    </a>
</div>