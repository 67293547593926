<script>
    import { onMount } from "svelte"
    import rest from "@common/services/restService"
    const url = "https://us-central1-nugmeg-project.cloudfunctions.net/helloWorld/hello";

    let val = "..."
    onMount(async () => {
        await call()
    });
    let call = async () => {
        let json = await rest.get(url)
        val = JSON.stringify(json, null, 4)
    }
</script>
<style>
    textarea {
        width: 99%;
        height: 50rem;
    }
</style>
<button on:click={call}>Call</button>
<textarea bind:value={val}></textarea>