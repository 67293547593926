<script lang="ts">
    import { onMount } from "svelte"
    import rest from "@common/services/restService"
    import type { DiagnoseListModel } from "@api"
    import "./DiagnoseTableCustomer"
    let diagnoses: DiagnoseListModel[] = []

    const getDiagnoses = async () => {
        diagnoses = await rest.get<DiagnoseListModel[]>("diagnose/bycustomer")
    }
    onMount(() => {
        getDiagnoses()
    })
</script>
<style>
    section {
        display: flex;
        justify-content: center;
    }
</style>
<article class="default">
    <header>
        <h1>Dine diagnoser</h1>
    </header>
    <section>
        <diagnose-table-customer diagnoses={diagnoses}></diagnose-table-customer>
    </section>
</article>