import restCacher from "@common/services/restCache"
import { StoredSubject, clonedObservable } from "@common/store/reactive"
import { currentLocation } from "@common/store/state"
import { checkIfAlready, getCurrentLoc } from "@common/services/geoloc"
import type { DepartmentViewModel } from "@api"
const url = "department"

const depsDefault: DepartmentViewModel[] = [{routeKey: "bergen"}]
const departmentsSubject = new StoredSubject<DepartmentViewModel[]>("bddepartments", depsDefault)
export const departments = clonedObservable(departmentsSubject)

const selectedDepartmentSubject = new StoredSubject<DepartmentViewModel>("bdselecteddepartment", {})
export const getSelectedDepartment = () => selectedDepartmentSubject.get()
export const setSelectedDepartment = (dep: DepartmentViewModel) => { selectedDepartmentSubject.next(dep) }
export const selectedDepartment = selectedDepartmentSubject.asObservable()

const fetchAll = async () => {
    const deps = await restCacher.get<DepartmentViewModel[]>(url)
    departmentsSubject.next(deps)
}
// const fetchAllByGeoQuery = async (loc: LngLatViewModel) => {
//     const deps = await restService.post<DepartmentViewModel[]>(`${url}/getbyquery`, loc)
//     departmentsSubject.next(deps)
// }
// const checkGeoLoc = async () => {
//     const alreadyAcceptedGeoLoc = await checkIfAlready()
//     if (alreadyAcceptedGeoLoc) {
//         sortByLoc()
//     }
// }
export const sortByLoc = async () => {
    const loc = await getCurrentLoc()
    currentLocation.next(loc)
    // fetchAllByGeoQuery(loc)
}
fetchAll()
// checkGeoLoc()


export const getDepartment = (id: string) => {
    const currentDeps = departmentsSubject.get()
    let dep = currentDeps.find(d => d.id === id)
    return dep
}
export const getDepartmentByRoute = (key: string) => {
    const currentDeps = departmentsSubject.get()
    if (!currentDeps)
        return null
    let dep = currentDeps.find(d => d.routeKey === key)
    return dep
}

export const getDepartmentAsync = async (id: string) => {
    let dep = getDepartment(id)
    if (!dep)
        dep = await restCacher.get<DepartmentViewModel>(`${url}/${id}`)
    return dep
}
