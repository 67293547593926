import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"
import { formatterNoDateAndTime } from "./DateTime" 

@customElement('date-time-viewer')
export class DateTimeViewer extends LitElement {
    _dateString: string
    _date: Date
    
    static styles = css`
        :host {
            display: inline-block;
        }
    `

    get date(): string {
        return this._dateString
    }
    set date(value: string) {
        this._dateString = value
        this._date = new Date(value)
        this.requestUpdate()
    }
    
    render() {
        if (!this._date)
            return html`N/A`
        return html`
            ${formatterNoDateAndTime.format(this._date)}
        `
    }
}