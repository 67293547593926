class TouchGestures {
    el: HTMLElement
    leftFn: Function
    rightFn: Function
    startX: number
    startY: number
    endX: number
    endY: number
    
    constructor(el: HTMLElement, leftFn:Function, rightFn:Function) {
        this.el = el;
        this.leftFn = leftFn;
        this.rightFn = rightFn;
        this.setupEvents();
    }
    touchStart(ev: TouchEvent) {
        this.startX = ev.changedTouches[0].screenX;
        this.startY = ev.changedTouches[0].screenY;
    }
    touchEnd(ev: TouchEvent) {
        this.endX = ev.changedTouches[0].screenX;
        this.endY = ev.changedTouches[0].screenY;
        this.handleGesture();
    }
    setupEvents() {
        this.el.addEventListener("touchstart", (e) => this.touchStart(e), {passive: true});
        this.el.addEventListener("touchend", (e) => this.touchEnd(e), false);
    }
    removeEvents() {
        this.el.removeEventListener("touchstart", (e) => this.touchStart(e));
        this.el.removeEventListener("touchend", (e) => this.touchEnd(e));
    }
    handleGesture() {
        const xDiff = this.endX - this.startX;
        if (xDiff > 0)
            this.leftFn();
        else
            this.rightFn();
    }
}

export default TouchGestures;
