import {LitElement, html, css} from "lit"
import {customElement} from "lit/decorators.js"
import {unsafeHTML} from "lit/directives/unsafe-html.js"
import {EditorState} from "prosemirror-state"
import {EditorView} from "prosemirror-view"
import {DOMParser} from "prosemirror-model"

import {setup} from "./setup"
import {bdSchema} from "./bdSchema"

import "./prose.css";
import type { BdEditorState } from "@common/models/interfaces"

import { debounceEvent } from "@common/funcs/helper"

@customElement('prose-editor')
export class ProseEditor extends LitElement {
    view
    edEl
    conEl
    _state: BdEditorState = { json: "", html: "<p>Skriv her...</p>"}
    _readonly: boolean = false
    interval: number
    debounceUpdate: Function
    constructor() {
        super()
        this.interval = 500
        this.debounceUpdate = debounceEvent((arg, event) => {
            this.updateEvent()
        }, 500, this.interval)
    }
    
    private updateEvent() {
        let evt = new CustomEvent('stateupdate', {
            detail: {
                json: this.json,
                html: this.html
            }
        })
        this.dispatchEvent(evt)
    }
    static styles = css`
        :host {
            display: block;
            height: 100%;
        }
        #editor {
            height: 100%;
        }
    `

    set readonly(value: boolean) {
        this._readonly = value
    }
    get readonly() {
        return this._readonly
    }
    set json(value: string) {
        if (value) {
            try {
                this._state.json = JSON.parse(value)
                this.requestUpdate()
            }
            catch (e) {
                console.log(`could not parse json ${value}`)
            }
        }
    }
    get json(): string {
        if (this.view && this.view.state) {
            const stateJson = this.view.state.toJSON()
            const stateJsonString = JSON.stringify(stateJson)
            return stateJsonString
        }
        return ""
    }
    get html(): string {
        if (this.view && this.view.state) {
            const markup = this.view.dom.innerHTML
            return markup
        }
        return ""
    }
    set html(value: string) {
        if (value) {
            this._state.html = value
            this.requestUpdate()
        }
    }
    updated(){
        if (this._state.json) {
            this.updateProseJson()
        } else {
            this.updateProseHtml()
        }
    }
    updateProseHtml() {
        const doc = DOMParser.fromSchema(this.view.state.schema).parse(this.conEl)
        let newState = EditorState.create({schema: this.view.state.schema, doc, plugins: this.view.state.plugins})
        this.view.updateState(newState)
    }
    updateProseJson() {
        let newState = EditorState.fromJSON({
            schema: this.view.state.schema,
            plugins: this.view.state.plugins
        }, this._state.json)
        this.view.updateState(newState)
    }
    initProse() {
        let proseState = EditorState.create({
            doc: DOMParser.fromSchema(bdSchema).parse(this.conEl),
            plugins: setup({schema: bdSchema}, () => {
                this.debounceUpdate()
            })
        })
        this.view = new EditorView(this.edEl, {
            state: proseState
        })
    }
    
    protected firstUpdated(): void {
        this.edEl = this.querySelector("#editor")
        this.conEl = this.querySelector("#content")
        this.initProse()
    }

    createRenderRoot() {
        return this
    }
    
    render() {
        let ht = "<p>Skriv her</p>"
        if (this._state.html) {
            ht = this._state.html
        }
        return html`
            <div id=editor style="margin-bottom: 23px"></div>

            <div id="content" style="display: none">
                ${unsafeHTML(ht)}
            </div>
        `
    }
}