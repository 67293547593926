import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"
import type { ImageDimensionsViewModel, ImageFileGroupViewModel, ImageSizeType } from "@api"

@customElement('image-viewer')
export class imageViewer extends LitElement {
    
    static styles = css`
        :host {
            display: block;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            /* background: black; */
            height: 100%;
            /* width: 100%; */
            
            max-height: 100%;
            max-width: 100%;
            margin:0;
            padding:0;
            /* border: 2px red solid; */
            box-sizing: border-box;
        }
        
        picture, img {
            /* flex-basis: 0;
            flex-grow: 1;
            flex-shrink: 1; */
            margin:0;
            padding:0;
            max-width: 100%;
            max-height: 100%;
        }
        .round {
            border-radius: 100%;
            box-shadow: 0 13px 26px rgba(#000, .2), 0 3px 6px rgba(#000, .2);
        }
        @media only screen and (max-width: 640px) {
            :host {
                width: 100%;
            }
        }
    `;

    @property({attribute: true})
    alt: string;

    @property({attribute: false})
    image: ImageFileGroupViewModel;
    
    @property({attribute: false})
    wantedsize: ImageSizeType;

    @property({attribute: false})
    round: boolean

    render() {
        if (!this.image)
            return html`N/A`;
        
        let url = "";
        let dimensions: ImageDimensionsViewModel = {width: 0, height: 0};
        let isMultipleFiles = true;

        if (this.wantedsize && this.image.scaled && this.image.scaled.length > 0 && this.image.scaled.some(s => s.sizeType === this.wantedsize)) {
            const wanted = this.image.scaled.find(s => s.sizeType === this.wantedsize);
            url = wanted.url;
            dimensions.width = wanted.dimensions ? wanted.dimensions.width : this.wantedsize;
            dimensions.height = wanted.dimensions ? wanted.dimensions.height : 0;
            isMultipleFiles = false;
        } else {
            if (this.image.isCropped) {
                dimensions.width = this.image.cropped.dimensions.width;
                dimensions.height = this.image.cropped.dimensions.height;
                url = this.image.cropped.url;
            } else if (this.image.original) {
                dimensions.width = this.image.original.dimensions.width;
                dimensions.height = this.image.original.dimensions.height;
                url = this.image.original.url;
            }
        }

        if (!this.image.scaled || this.image.scaled.length === 0) {
            isMultipleFiles = false;
        }
        // height="${dimensions.height}"
        if (!isMultipleFiles) {
            return html`
                <img src="${url}" alt="${this.alt}" class="${this.round ? "round ": ""}" width="${dimensions.width}">
            `;
        }
        else {
            let portrait = false
            if (dimensions.height > dimensions.width)
                portrait = true
            return html`
                <picture>
                    ${this.image.scaled.map(m => {
                        return html`
                            <source 
                                type="${this.image.contentType}"    
                                media="${portrait ? `(max-height: ${m.dimensions.height}px)` : `(max-width: ${m.dimensions.width}px)`}" 
                                srcset="${m.url}">
                        `
                    })}
                    
                        
                    ${portrait ? html`
                        <img src="${url}" alt="${this.alt}" loading="lazy" height=${dimensions.height}>
                    ` : html`
                        <img src="${url}" alt="${this.alt}" loading="lazy" width=${dimensions.width}>
                    `}
                    
                </picture>
            `
        }
    }
}

// case ImageSizeType.Thumbnail:
// return "(max-width: 599px)";
// case ImageSizeType.Small:
//     return "(min-width: 600px)";
// case ImageSizeType.Medium:
//     return "(min-width: 1200px)";
// case ImageSizeType.Big:
//     return "(min-width: 2000px)";
// const srcset = this.image.scaled.map(s => {
//     return `${s.url} ${s.sizeType}w`;
// }).join(",")
// srcset="${srcset}"
//     sizes="(max-width: 300px) 600px,
//             (min-width: 600px) 1500px,
//             (min-width: 1200px) 2500px,
//             3000px"
// />