<script lang="ts">
    import { onMount } from "svelte"
    import { substate, refreshSubState } from "@app/store/paymentsStore"
    import rest from "@common/services/restService"
    import { ContentStaticIdentifier, type CheckoutPostModel, type RedirectModel } from "@api"
    import config from "@common/config"

    import {getContent} from "@common/store/contentPublicStore"
    
    let content: ContentViewModel = {
        identifier: ContentStaticIdentifier.MembersPage,
        richText: { html: ""}
    }

    const urlCheckout = "paymentApi/create-checkout-session"
    const urlPortal = "paymentApi/create-portal-session"

    const priceId = config.subscriptionClubPriceId
    
    const checkout = async () => {
        const data: CheckoutPostModel = {priceId}
        const res = await rest.post<RedirectModel>(urlCheckout, data)
        if (res.locationUrl)
            window.location.replace(res.locationUrl)
    }
    const portal = async () => {
        const res = await rest.get<RedirectModel>(urlPortal)
        if (res.locationUrl)
            window.location.replace(res.locationUrl)
    }
    onMount(async () => {
        refreshSubState()
        content = await getContent(ContentStaticIdentifier.MembersPage)
    })
</script>
<style>
    
</style>
<article class="default">
    <header>
        <h1>Medlemskap</h1>
    </header>
    <section>
        {#if !$substate.isActive}
            <div class="product">
                <div class="description">
                    {@html content.richText.html}
                </div>
            </div>
            <div>
                <button id="checkout-button" on:click|preventDefault={checkout}>Klikk her for å starte ditt medlemskap</button>
            </div>
        {:else}
            
            <h3>Du er medlem av klubben!</h3>
            <p>Se fordelene på <a href="https://bildoktoren.no">Bildoktorens hovedside</a></p>

            {#if $substate.endDate}
                <p>Medlemskapet er kansellert fra og med <date-viewer date={$substate.endDate}></date-viewer>
            {/if}
            <div>
                <button id="portal-button" on:click|preventDefault={portal}>Behandle abonnement</button>
            </div>
        {/if}
    </section>
</article>
