import { StoredSubject, clonedObservable } from "@common/store/reactive"
import type { OrderLinePostModel, ProductViewModel, ProductVariantViewModel } from "@api"
import { cloneDeep } from "lodash-es"
import { uuid } from "@common/funcs/helper"

const subject = new StoredSubject<OrderLinePostModel[]>("bdOrderLines", [])
export const cartProductLines = clonedObservable(subject)
export const getCartLines = () => {
    const lines = subject.getValue()
    const clone = cloneDeep(lines)
    return clone
}
export const addProductLine = (product: ProductViewModel, variant: ProductVariantViewModel, quantity: number) => {
    let line: OrderLinePostModel = {
        id: uuid(),
        product,
        variant,
        quantity: quantity
    }
    subject.next([line])
}

export const ResetLines = () => {
    subject.next([])
}

export const removeProductLine = (productLine: OrderLinePostModel) => {
    const newValue = subject.getValue().filter(p => p.id !== productLine.id)
    subject.next(newValue)
}

export const updateProductLine = (productLine) => {
    const currList = subject.getValue()
    const index = currList.findIndex(p => p.id === productLine.id)
    if (index > -1) {
        const newValue = cloneDeep(currList)
        newValue.splice(index, 1, productLine)
        subject.next(newValue)
    }
}