import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"
import type { AssetContentViewModel } from "@api"

@customElement('image-carusel')
export class imageViewer extends LitElement {
    private _interval = 10000
    private _index = 0
    private _intervalId: number
    static styles = css`
        :host {
            display: block;
            width: 100%;
            max-width: 100%;
        }
        
    `

    @property({attribute: false})
    interval: number

    @property({attribute: false})
    assets: AssetContentViewModel[]

    connectedCallback() {
        super.connectedCallback()
    }
    setupInterval() {
        if (this._intervalId)
            window.clearInterval(this._intervalId)
        let interval = this._interval
        if (this.interval)
            interval = this.interval * 1000

        if (interval < 5000)
            interval = 5000
        this._intervalId = window.setInterval(() => {
            const length = this.assets ? (this.assets.length - 1) : 0
            if (length > 0) {
                if (length > this._index)
                    this._index += 1
                else
                    this._index = 0
                this.requestUpdate()
            }
        }, interval)
    }
    disconnectedCallback() {
        super.disconnectedCallback()
        window.clearInterval(this._intervalId)
    }
    render() {
        if (!this._intervalId && this.interval)
            this.setupInterval()

        if (this.assets && Array.isArray(this.assets) && this.assets.length > 0) {
            let asset = this.assets[this._index]
            const image = html`<image-viewer class="carusel-pic" .image=${asset.asset} alt=${asset.text}></image-viewer>`
            if (asset.link)
                return html`<a href=${asset.link} target="_blank">${image}</a>`
            return image
            
        }
        return html`<p>.</p>`
    }
}
