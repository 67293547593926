import { html, css, LitElement } from "lit"
import {customElement, property} from "lit/decorators.js"

@customElement('rating-stars-view')
export class RatingStars extends LitElement {

    emptyStarColor = "#9b9b9b" //"#737373"
	fullStarColor = "#e9ba26" //"#ed8a19"
    emptyStar = 0
    fullStar = 1
    totalStars = 5
    styleStarWidth = 30
    styleStarHeight = 30
    stars = []
    static get styles() {
        return css`
            :host {
                display: flex;
		        justify-content: center;
            }
            .star-rating {
		        display: flex;
                flex-direction: row;
            }
            .star-container:not(:last-child) {
                margin-right: 5px;
            }
        `;
    }
    @property({attribute: true, type: Number})
    rating: number;
    
    getStarPoints() {
        let centerX = this.styleStarWidth / 2
        let centerY = this.styleStarWidth / 2
        let innerCircleArms = 5
        let innerRadius = this.styleStarWidth / innerCircleArms
        let innerOuterRadiusRatio = 2.5 // Unique value - determines fatness/sharpness of star
        let outerRadius = innerRadius * innerOuterRadiusRatio
        return this.calcStarPoints(centerX, centerY, innerCircleArms, innerRadius, outerRadius)
    }
    calcStarPoints(centerX, centerY, innerCircleArms, innerRadius, outerRadius) {
        let angle = Math.PI / innerCircleArms
        let angleOffsetToCenterStar = 60
        let totalArms = innerCircleArms * 2
        let points = ""
        for (let i = 0; i < totalArms; i++) {
            let isEvenIndex = i % 2 == 0
            let r = isEvenIndex ? outerRadius : innerRadius
            let currX = centerX + Math.cos(i * angle + angleOffsetToCenterStar) * r
            let currY = centerY + Math.sin(i * angle + angleOffsetToCenterStar) * r
            points += currX + "," + currY + " "
        }
        return points
    }
    initStars() {
        for (let i = 0; i < this.totalStars; i++) {
            this.stars.push({
                raw: this.emptyStar,
                percent: this.emptyStar + "%"
            })
        }
    }
    setStars() {
        let fullStarsCounter = Math.floor(this.rating);
        for (let i = 0; i < this.stars.length; i++) {
            if (fullStarsCounter !== 0) {
                this.stars[i].raw = this.fullStar
                this.stars[i].percent = this.calcStarFullness(this.stars[i])
                fullStarsCounter--
            } else {
                let surplus = Math.round((this.rating % 1) * 10) / 10; // Support just one decimal
                let roundedOneDecimalPoint = Math.round(surplus * 10) / 10
                this.stars[i].raw = roundedOneDecimalPoint
                return (this.stars[i].percent = this.calcStarFullness(this.stars[i]))
            }
        }
    }
    getFullFillColor(starData) {
        return starData.raw !== this.emptyStar
            ? this.fullStarColor
            : this.emptyStarColor;
    }
    calcStarFullness(starData) {
        let starFullnessPercent = starData.raw * 100 + "%"
        return starFullnessPercent
    }

    constructor() {
        super()
		this.initStars();
    }
    render() {
        this.setStars();
        return html`
            <!-- <div>${this.rating} rating</div> -->
            <div class="star-rating">
                ${this.stars.map(star => {
                    return html`
                        <div class="star-container">
                            <svg class="star-svg" 
                                style="fill: url(#gradient${star.raw}); width:30; height: 30">
                                <polygon points="${this.getStarPoints()}" style="fill-rule:nonzero;"/>
                                <defs>
                                    <linearGradient id="gradient${star.raw}">
                                        <stop
                                            id="stop1"
                                            offset="${star.percent}"
                                            stop-opacity="1"
                                            stop-color="${this.getFullFillColor(star)}"
                                        ></stop>
                                        <stop
                                            id="stop2"
                                            offset="${star.percent}"
                                            stop-opacity="0"
                                            stop-color="${this.getFullFillColor(star)}"
                                        ></stop>
                                        <stop
                                            id="stop3"
                                            offset="${star.percent}"
                                            stop-opacity="1"
                                            stop-color="${this.emptyStarColor}"
                                        ></stop>
                                        <stop
                                            id="stop4"
                                            offset="100%"
                                            stop-opacity="1"
                                            stop-color="${this.emptyStarColor}"
                                        ></stop>
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                    `
                })}
            </div>
        `
    }
}