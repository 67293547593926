<script lang="ts">
    import type { SvelteComponent } from "svelte"
    import { curRoute } from "@common/routing/router"
    const rootPath = window.location.origin
    let href = window.location.href

    let component: SvelteComponent, param: string, action: string
    let path = ""
    let title = ""
    curRoute.subscribe(route => {
        path = route.path
        component = route.component
        param = route.param
        action = route.action
        title = route.title ? route.title : "hjem"
        href = window.location.href
    })

</script>

<style>
    .main-container {
        background: var(--bd-background-primary);
        overflow-y: auto;
        display: flex;
        justify-content: center;
        min-height: 100%;
    }
    .center-container {
        width: 100%;
        min-height: 100%;
    }
</style>
<svelte:head>

    <title>Bildoktoren.no - {title}</title>

    <!-- <link rel="canonical" href="{href}">
     -->
</svelte:head>
<section class="main-container">
    
    <div class="center-container">
        {#if param}
            {#if action}
                <svelte:component this={component} param={param} action={action} />
            {:else}
                <svelte:component this={component} param={param} />
            {/if}
        {:else}
            <svelte:component this={component} />
        {/if}
    </div>
    
</section>
