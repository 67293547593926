import { html, render } from "lit-html"

class CheckMarkGreen extends HTMLElement {
    template
    constructor() {
        super();
        this.attachShadow({mode: "open"});
        this.template = html`
            <style>
            :host {
                display: inline-flex;
            }
            img {
                height: 30px;
            }
        </style>
            <img src="https://storage.googleapis.com/bildoktoren-no.appspot.com/assets/icons/check-mark-green-100.png" class="icon" alt="check-mark">
        `;
    }
    connectedCallback() {
        render(this.template, this.shadowRoot);
    }
}

customElements.define("check-mark-green", CheckMarkGreen);