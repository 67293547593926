import {LitElement, html, css} from "lit"
import {customElement} from "lit/decorators.js"
import { blogStoreFiltered } from "@common/store/blogPublicStore"
import type { BlogPostViewModel } from "@api"
import SortFuncs from "@common/funcs/sortFuncs"
import { observe } from "@common/directives/ObservableDirective"

@customElement('blog-front-page')
export class BlogFrontPage extends LitElement {

    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            gap: 3rem;
        }
    `
    renderPost(posts: BlogPostViewModel[]) {
        let filterd = posts.filter(b => b.onFrontPage)
        if (filterd && filterd.length > 0) {
            let _sortFunc = new SortFuncs()
            let sortFn = _sortFunc.getSortFn(filterd, "order")
            filterd.sort(sortFn)
            return filterd.map((p, i) => {
                let left = i%2 === 0
                return html`<blog-widget .imgleft=${left} .post=${p}></blog-widget>`
            })
        }
        return html`<span class="noposts"></span>`
    }
    
    render() {
        return html`<div>
            ${observe(blogStoreFiltered, this.renderPost)}
        </div>`
    }
}