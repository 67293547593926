import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"
import { getDepartment } from "@common/store/departmentPublicStore"
import { ImageSizeType } from "@api"
import { goto } from "@common/routing/router"
import type { DepartmentViewModel } from "@api"

@customElement('dep-card')
export class DepCard extends LitElement {

    static styles = css`
        :host {
            display: inline-flex;
            min-width: 200px;
            flex-direction: column;
            text-align: center;
            background: #bccfdb;
            border-radius: 5px;
            transition: background .6s ease;
            padding: 1rem;
        }
        .thumbnail {
            width: 124px;
            height: 124px;
            display: flex;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 1.5em;
        }
        h1 {
            font-size: larger;
            color: var(--mdc-theme-on-surface);
        }
        h2 {
            font-size: medium;
        }
        a.goto-link {
            font-size: 1.6rem;
            color: black;
            text-decoration: none;
            border-bottom: 2px solid var(--mdc-theme-primary);
        }
        distance-viewer {
            margin: 0 auto;
        }
    `;

    @property({attribute: true}) 
    id: string;

    @property({attribute: false}) 
    dep: DepartmentViewModel;
    
    render() {
        let dep = this.dep;
        if (this.id)
            dep = getDepartment(this.id)
        let displayDetails = true
        return html`
            <image-viewer class="thumbnail" 
                .image=${dep.mainPicture} 
                .wantedsize=${ImageSizeType.Thumbnail} 
                .round=${true}
                alt="main">
            </image-viewer>
            <a class="goto-link" @click=${goto} href="/${dep.routeKey}">
                ${dep.name}
            </a>
            
            ${displayDetails && dep.distanceMeters > 0?
                html`
                    <distance-viewer distance=${dep.distanceMeters}></distance-viewer>
                ` : ""
            }
            
            <!-- <rating-stars-view rating=${dep.rating}></rating-stars-view> -->
        `;
    }
}