<script lang="ts">
    import FullscreenDialog from "@common/components/Dialogs/FullscreenDialog.svelte"
    import type { DiagnoseViewModel } from "@api"
    import type { Subscription } from "rxjs"
    export let diagnose: DiagnoseViewModel
    
    let viewDialogState = true
    let num = 0
    import { curRoute, gotoPath, setSearchParam } from "@common/routing/router"
    let sub: Subscription
    import { onMount, onDestroy } from "svelte"
    
    onMount(() => {
        sub = curRoute.subscribe(r => {
            if (r.search) {
                if (r.search.has("num")) {
                    const numString = r.search.get("num")
                    num = parseInt(numString)
                }
            }
        })
    })
    const setSel = (e: CustomEvent) => {
        let num = e.detail.num
        setSearchParam("num", `${num}`)
    }
    const close = () => {
        const urlEdit = `/diagnoses/${diagnose.id}/edit?tab=2`
        gotoPath(urlEdit)
    }
    onDestroy(() => sub.unsubscribe())
</script>

<FullscreenDialog open={viewDialogState}
    onClose={close}>
    <image-list-viewer 
        slot="dlgContent"
        images={diagnose.pictures}
        selectedIndex="{num}"
        on:setselectedindex={setSel}>
    </image-list-viewer>
</FullscreenDialog>