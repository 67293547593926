import {wrapItem, blockTypeItem, Dropdown, joinUpItem, liftItem, selectParentNodeItem, icons, MenuItem} from "prosemirror-menu"
import {undo, redo} from "prosemirror-history"
import {NodeSelection} from "prosemirror-state"
import {toggleMark} from "prosemirror-commands"
import {wrapInList} from "prosemirror-schema-list"
import {TextField, openPrompt} from "./prompt"
import lang from "@common/services/lang"


function canInsert(state, nodeType) {
    let $from = state.selection.$from
    for (let d = $from.depth; d >= 0; d--) {
        let index = $from.index(d)
        if ($from.node(d).canReplaceWith(index, index, nodeType)) return true
    }
    return false
}
function insertBdImage(bdImageType, id) {
    return function(state, dispatch?: any) {
        let {$from} = state.selection, index = $from.index()
        if (!$from.parent.canReplaceWith(index, index, bdImageType))
            return false
        if (dispatch)
            dispatch(state.tr.replaceSelectionWith(bdImageType.create({id})))
        return true
    }
}
function insertImageItem(nodeType) {
    return new MenuItem({
        title: "Insert image",
        label: "Image",
        enable(state) {
            return canInsert(state, nodeType)
        },
        run(state, _, view) {
            let {from, to} = state.selection, attrs = null;
            if (state.selection instanceof NodeSelection && state.selection.node.type == nodeType)
                attrs = state.selection.node.attrs;
            openPrompt({
                title: "Insert image",
                fields: {
                    src: new TextField({label: "Location", required: true, value: attrs && attrs.src}),
                    title: new TextField({label: "Title", value: attrs && attrs.title}),
                    alt: new TextField({label: "Description",
                        value: attrs ? attrs.alt : state.doc.textBetween(from, to, " ")})
                },
                callback(attrs) {
                    view.dispatch(view.state.tr.replaceSelectionWith(nodeType.createAndFill(attrs)))
                    view.focus();
                }
            })
        }
    })
}

function cmdItem(cmd, options) {
    let passedOptions = {
        label: options.title,
        run: cmd
    };
    for (let prop in options)
        passedOptions[prop] = options[prop];
    
    if ((!options.enable || options.enable === true) && !options.select)
        passedOptions[options.enable ? "enable" : "select"] = state => cmd(state);

    return new MenuItem(passedOptions);
}

function markActive(state, type) {
    let {from, $from, to, empty} = state.selection;
    if (empty) return type.isInSet(state.storedMarks || $from.marks());
    else return state.doc.rangeHasMark(from, to, type);
}

function markItem(markType, options) {
    let passedOptions = {
        active(state) { return markActive(state, markType); },
        enable: true
    };
    for (let prop in options) passedOptions[prop] = options[prop];
    return cmdItem(toggleMark(markType), passedOptions);
}

function linkItem(markType) {
    return new MenuItem({
        title: lang("add-or-remove-link"),
        icon: icons.link,
        active(state) { return markActive(state, markType); },
        enable(state) { return !state.selection.empty; },
        run(state, dispatch, view) {
            if (markActive(state, markType)) {
                toggleMark(markType)(state, dispatch);
                return true;
            }
            openPrompt({
                title: "Create a link",
                fields: {
                    href: new TextField({
                        label: "Link target",
                        required: true
                    }),
                    title: new TextField({label: "Title"})
                },
                callback(attrs) {
                    toggleMark(markType, attrs)(view.state, view.dispatch);
                    view.focus();
                }
            });
        }
    });
}

function wrapListItem(nodeType, options) {
    return cmdItem(wrapInList(nodeType, options.attrs), options)
}

export const buildMenuItems = (schema, isAdmin?: boolean) => {
    let r = {
        fullMenu: {}
    };
    minimumMenu(schema, r);
    if (isAdmin)
        addAdminMenuItems(schema, r)
    return r;
}

const minimumMenu = (schema, r) => {
    if (schema.marks.strong)
        r.toggleStrong = markItem(schema.marks.strong, {title: lang("toggle-strong-style"), icon: icons.strong});
    
    if (schema.marks.em)
        r.toggleEm = markItem(schema.marks.em, {title: lang("toggle-emphasis"), icon: icons.em});
    
    if (schema.marks.link)
        r.toggleLink = linkItem(schema.marks.link);

    if (schema.nodes.bullet_list)
        r.wrapBulletList = wrapListItem(schema.nodes.bullet_list, {
            title: lang("wrap-in-bullet-list"),
            icon: icons.bulletList
        });
    if (schema.nodes.ordered_list)
        r.wrapOrderedList = wrapListItem(schema.nodes.ordered_list, {
            title: lang("wrap-in-ordered-list"),
            icon: icons.orderedList
        });
    if (schema.nodes.blockquote)
        r.wrapBlockQuote = wrapItem(schema.nodes.blockquote, {
            title: lang("wrap-in-block-quote"),
            icon: icons.blockquote
        });
    
    if (schema.nodes.heading)
        for (let i = 1; i <= 10; i++)
            r["makeHead" + i] = blockTypeItem(schema.nodes.heading, {
                title: `${lang("change-to-heading")} ${i}`,
                label: `${lang("heading")} ${i}`,
                attrs: {level: i}
            });
    if (schema.nodes.horizontal_rule) {
        let hr = schema.nodes.horizontal_rule;
        r.insertHorizontalRule = new MenuItem({
            title: lang("insert-horizontal-rule"),
            label: lang("horizontal-rule"),
            enable(state) { return canInsert(state, hr); },
            run(state, dispatch) { dispatch(state.tr.replaceSelectionWith(hr.create())); }
        });
    }

    let cut = arr => arr.filter(x => x);
    r.insertMenu = new Dropdown([r.insertHorizontalRule], {label: "sett inn"})
    let bdImageType = schema.nodes.bdImage
    
    r.insertMenu.content.push(new MenuItem(
        {
            title: "Insert img",
            label: "bdimg",
            enable(state) {
                return insertBdImage(bdImageType, "")(state)
            },
            run(state, dispatch, view) {
                
                openPrompt({
                    title: "Insert image",
                    fields: {
                        id: new TextField({label: "Location", required: true, value: "id"})
                    },
                    callback(attrs) {
                        insertBdImage(bdImageType, attrs.id)(state, dispatch)
                    }
                })
            }
        }
    ))

    r.typeMenu = new Dropdown(
        cut([
            r.makeHead1, r.makeHead2, r.makeHead3, r.makeHead4, r.makeHead5, r.makeHead6
        ]), {label: lang("headline")});

    // r.typeMenu = new Dropdown(cut([r.makeParagraph, r.makeCodeBlock, r.makeHead1 && new DropdownSubmenu(cut([
    //     r.makeHead1, r.makeHead2, r.makeHead3, r.makeHead4, r.makeHead5, r.makeHead6
    // ]), {label: "Heading"})]), {label: "Type..."});

    let undoItem = new MenuItem({
        title: lang("undo-last-change"),
        run: undo,
        enable: state => undo(state),
        icon: icons.undo
    })
    
    let redoItem = new MenuItem({
        title: lang("redo-last-undone-change"),
        run: redo,
        enable: state => redo(state),
        icon: icons.redo
    })

    r.inlineMenu = [cut([r.toggleStrong, r.toggleEm, r.toggleCode, r.toggleLink])];
    r.blockMenu = [cut([r.wrapBulletList, r.wrapOrderedList, r.wrapBlockQuote, joinUpItem,
        liftItem, selectParentNodeItem])];
    r.fullMenu = r.inlineMenu.concat([[r.insertMenu, r.typeMenu]], [[undoItem, redoItem]], r.blockMenu);

    return r;
}


const addAdminMenuItems = (schema, r) => {
    
    if (schema.marks.code)
        r.toggleCode = markItem(schema.marks.code, {title: lang("toggle-code-font"), icon: icons.code});

    if (schema.nodes.image)
        r.insertImage = insertImageItem(schema.nodes.image)

    if (schema.nodes.paragraph)
        r.makeParagraph = blockTypeItem(schema.nodes.paragraph, {
            title: "Change to paragraph",
            label: "Plain"
        });
    if (schema.nodes.code_block)
        r.makeCodeBlock = blockTypeItem(schema.nodes.code_block, {
            title: "Change to code block",
            label: "Code"
        });
    return r;
}