<script lang="ts">
    import { userIsLoggedIn } from "@common/store/userProfile"
    import { uiLoginGoogle, uiLoginFb, uiLoginEmailPass, uiForgotPassword} from "@common/services/loginInterfaceService"
    import lang from "@common/services/lang"
    import GoogleLoginButton from "@common/components/Logos/GoogleLoginButton.svelte"
    import FacebookLoginButton from "@common/components/Logos/FacebookLoginButton.svelte"

    let email: string, pass: string, msg = ""
    const loginGoogle = async (e: Event) => {
        e.preventDefault()
        msg = await uiLoginGoogle()
    };
    const loginFb = async (e: Event) => {
        e.preventDefault()
        msg = await uiLoginFb()
    };
    const loginEmail = async (e: Event) => {
        e.preventDefault()
        msg = await uiLoginEmailPass(email, pass)
    };

    const forgotPassword = async (e: Event) => {
        e.preventDefault()
        msg = await uiForgotPassword(email)
    };
</script>
<style>
    article {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: center;
        align-items: center
    }
    input {
        flex: 1;
        width: 299px;
        margin-bottom: 0.4rem;
    }
</style>
<article>
    {#if $userIsLoggedIn}
        <p>{lang('logged-in')}</p>
    {:else}
        <div>
            <h1>{lang('login')}</h1>
            <p>
                <GoogleLoginButton on:click={loginGoogle} />
                <br><br>
                <!-- <FacebookLoginButton on:click={loginFb} /> -->
            </p>
            <p>Eller logg inn med epost og passord</p>
            <form>
                <input type="text" bind:value="{email}" id="email" autocomplete="email" placeholder="{lang('email')}" />
                <input type="password" id="current-password" autocomplete="current-password" bind:value="{pass}" placeholder="{lang('password')}" />
                <button on:click="{loginEmail}">{lang('login')}</button>
            </form>
            <p>{msg}</p>
            <p>
                <a href="/#" on:click={forgotPassword}>{lang('forgot-password')}</a>
            </p>
        </div>
    {/if}
</article>
