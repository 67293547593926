<script lang="ts">
    import { onMount } from "svelte"
    import type { DiagnoseViewModel } from "@api"
    export let param = ""
    export let action = ""

    let diagnose: DiagnoseViewModel
    import { getDiagnoseDetails } from "@common/store/diagnoseStore"
    onMount(async () => {
        diagnose = await getDiagnoseDetails(param)
    })

    import View from "./DiagnoseView.svelte"
    import Images from "@common/components/Files/ImagesView.svelte"
</script>

{#if diagnose}
    {#if action == "images"}
        <Images diagnose={diagnose} />
    {:else}
        <View diagnose={diagnose} />
    {/if}
{:else}
    <p>Laster...</p>
{/if}