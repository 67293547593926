import { SvelteSubject } from "@common/store/reactive"
import type { Toast } from "@common/models/interfaces"

class ToastSubject extends SvelteSubject<Toast[]> {

    add(toast:Toast, timeout?: number) {
        if (!timeout)
            timeout = 5
        const list = this.get()
        list.push(toast)
        this.next(list)
        setTimeout(() => {
            this.remove(toast)
        }, timeout * 1000)
    }

    remove(toast: Toast) {
        const list = this.getValue()
        const index = list.indexOf(toast)
        if (index > -1) {
            list.splice(index, 1)
            this.next(list)
        }
    }

    reset(){
        this.next([])
    }
}

export default new ToastSubject([])