import {LitElement, html, css} from "lit"
import {customElement} from "lit/decorators.js"

@customElement('bd-button')
export class DirectionSign extends LitElement {
    
    static styles = css`
        :host {
            --bd-button-height: 30px;
            --bd-button-background: var(--mdc-theme-primary);
            --bd-button-color: var(--mdc-theme-on-primary);
            display: inline-block;
            display: inline-flex;
            padding: 2px;
            transition: background .1s ease;
        }
        .button {
            border-radius: 5px;
            background: var(--bd-button-background);
            font-weight: 900;
            color: var(--bd-button-color);
            cursor: pointer;
            display: inline-block;
            font-size: 1rem;
            height: var(--bd-button-height);
            line-height: var(--bd-button-height);
            padding: 0 1rem;
            text-align: center;
        }
        
    `;
    
    render() {
        return html`        
            <div class="button">
                <slot></slot>
            </div>
        `
    }
}