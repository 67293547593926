import "@app/style/imports.css"
import "@common/style/vars.css"
import "@common/style/theme.css"
import "@common/style/global.css"
import "@app/style/layout.css"

import App from "./App.svelte"

const app = new App({
    target: document.body
})

export default app
