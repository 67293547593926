import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"

@customElement('direction-sign')
export class DirectionSign extends LitElement {
    
    static styles = css`
        :host {
            --bd-sign-height: 30px;
            --bd-sign-background: #ffcc00;
            --bd-sign-color: black;
            display: inline-block;
            background: var(--bd-sign-color);
            font-family: 'Libre Franklin', sans-serif;
            display: inline-flex;
            padding: 2px;
            transition: background .1s ease;
        }
        .sign-tag {
            background: var(--bd-sign-background);
            font-weight: 900;
            color: var(--bd-sign-color);
            cursor: pointer;
            display: inline-block;
            font-size: 1rem;
            height: var(--bd-sign-height);
            line-height: var(--bd-sign-height);
            padding: 0 1rem;
            text-align: center;
        }
        .triangle-right {
            --bd-sign-triangle-background: var(--bd-sign-background);
            --bd-sign-triangle-color: var(--bd-sign-color);
            width: 0;
            height: 0;
            border-top: calc(var(--bd-sign-height)/2) solid var(--bd-sign-triangle-color);
            border-left: calc((var(--bd-sign-height)*3)/5) solid var(--bd-sign-triangle-background);
            border-bottom: calc(var(--bd-sign-height)/2) solid var(--bd-sign-triangle-color);
        }
        :host(:hover) {
            --bd-sign-background: var(--bildoktoren-blue-sharp);
            --bd-sign-color: #fff;
        }
        :host(:hover) .sign-tag {
            border-radius: 4px 0 0 4px;
        }
        :host(:hover) .triangle-right {
            border-radius: 0 4px 4px 0;
            --bd-sign-triangle-background: var(--bd-sign-background);
            --bd-sign-triangle-color: var(--bd-sign-background);
        }
    `;

    @property({attribute: true})
    text: string
    
    render() {
        return html`        
            <div class="sign-tag">${this.text}</div>
            <div class="triangle-right"></div>
        `
    }
}