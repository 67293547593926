<script lang="ts">
    import { getCurRoute, goto } from "@common/routing/router"
    import lang from "@common/services/lang"
    export let label = ""
    export let append = ""
    export let route = ""
    export let langCode = ""
    export let color = ""
    export let background = ""
    export let icon = ""
    export let onHover = null
    export let decor = false
    let title = ""
    
    $ : if (langCode || append || label) {
        if (langCode) {
            const translation = lang(langCode)
            if (translation)
                title = translation
            else
                title = langCode
        }
        if (label)
            title = label
        if (append) {
            route = `${getCurRoute()}${append}`
        }
    }
    //let hovering = false
    let enter = () => {
        //hovering = true
        if (onHover)
            onHover();
    };
	let leave = () => {
        // hovering = false  
    }
</script>

<style>
    a {
        text-decoration: none;
        white-space: nowrap;
        color: var(--mdc-theme-text-primary-on-background);
        margin: 0 0.1rem;
        line-height: 2rem;
    }
    a.decor {
        text-decoration: underline;
    }
    a.icon {
        font-size: 1.4rem;
    }
    a:hover {
        text-decoration: underline;
    }
    a.light {
        color: var(--mdc-theme-text-primary-on-dark);
    }
    a.primary {
        color: var(--mdc-theme-primary);
    }
    a.backlight {
        padding: 0.2rem;
        border-radius: 0.2rem;
        background-color: var(--mdc-theme-text-primary-on-dark);
    }
    a.backdark {
        padding: 0.2rem;
        border-radius: 0.2rem;
        background-color: var(--mdc-theme-primary);
    }
</style>

{#if icon}
    <a href={route} class="icon" on:click={goto} 
        class:primary={color == "primary"}
        class:light={color == "light"} 
        class:backlight={background == "light"}
        class:backdark={background == "dark"} 
        title={title} 
        on:mouseenter={enter} 
        on:mouseleave={leave}>
        <span class="material-icons">{icon}</span>
    </a>
{:else}
    <a href={route} on:click={goto} 
        class:primary={color == "primary"}    
        class:light={color == "light"} 
        class:backlight={background == "light"} 
        class:backdark={background == "dark"} 
        class:decor
        title={title} 
        on:mouseenter={enter} 
        on:mouseleave={leave}>
        <slot>{title}</slot>
    </a>
{/if}