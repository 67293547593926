<script lang="ts">
    import {onMount, onDestroy} from "svelte"
    import Check from "@app/components/Signs/Check.svelte"
    import config from "@common/config"
    const urlPortal = `${config.apiurl}/PaymentApi/create-portal-session`
    import { curRoute, goto } from "@common/routing/router"
    import type { Subscription } from "rxjs";
    import rest from "@common/services/restService"
    let sessionId = ""
    let sub: Subscription
    onMount(() => {
        sub = curRoute.subscribe(r => {
            if (r.search) {
                if (r.search.has("session_id")) {
                    sessionId = r.search.get("session_id")
                    linkUserToSession()
                }
            }
        })
    })
    const linkUserToSession = async () => {
        const url = "PaymentApi/updateuserfromsessionid"
        await rest.post(url, {sessionId})
    }
    onDestroy(() => sub.unsubscribe())
</script>
<style>
    
    
</style>
<article class="default">
    <header>
        <h1>Medlemskap</h1><br>
        <h2>Gratulerer med ditt medlemskap i Bildoktoren</h2>
    </header>
    <section>
        <Check />
        <br>
        <a on:click={goto} href="/membership">
            <h3>Gå tilbake til Medlemskap oversikten</h3>
        </a>
    </section>
</article>
