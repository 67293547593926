import { gotoPath } from "@common/routing/router"
import { userIsLoggedIn, userAuthInfo } from "@common/store/userProfile"
import type firebase from "firebase/app"
import auth from "@common/services/authentication"
import { getInitials } from "@common/funcs/helper"

class UserAuth {
    constructor() {
        this.init()
    }
    async init() {
        
        userIsLoggedIn.subscribe(async value => {
            if (!value) {
                this.setLoggedOutUserProfile()
            }
        })
    }
    async onuserIsLoggedIn(user: firebase.User) {
        if (user) {
            userIsLoggedIn.set(true)
            return this.setLoggedInUserProfile(user)
        }
    }
    async setLoggedInUserProfile(user: firebase.User) {
        if (!user)
            user = auth.getCurrentUser()
        
        if (!user)
            return
        
        const isAdmin = await auth.isUserAdmin()
        const isSeller = await auth.isSeller()
        const { providerData } = user
        const email = user.email ?? providerData[0].email
        const up = {
            loggedIn: true,
            uid: user.uid,
            email,
            emailVerified: user.emailVerified,
            name: user.displayName,
            displayName: user.displayName,
            initials: getInitials(email),
            providerData,
            photoURL: user.photoURL,
            isAdmin,
            isSeller
        }
        userAuthInfo.set(up)
        const postLoginPath = localStorage.getItem("postLoginPath")
        localStorage.removeItem("postLoginPath")
        if (postLoginPath)
            gotoPath(postLoginPath)
        return up
    }
    setLoggedOutUserProfile() {
        userAuthInfo.set({ loggedIn: false, name: "anon", initials: "U", emailVerified: false })
    }

    updateCurrentUser(displayName) {
        const user = auth.getCurrentUser();
        if (user) {
            return user.updateProfile({
                displayName
            })
        }
    }
}
export default new UserAuth()
