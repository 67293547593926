<script lang="ts">
    
</script>
<style>
    
    
</style>
<article class="default">
    <header>
        <h2>Medlemskap ble ikke opprettet</h2>
        
    </header>
    <section>
        <p>Kjøpet ble kansellert</p>
    </section>
</article>
