import firebase from "firebase/app"
import "firebase/auth"

import { gotoPath } from "@common/routing/router"
class Authentication {
    root: string
    alreadySentVerification = false
    constructor() {
        this.root = `${window.location.origin}/`
    }
    fauth() {
        if (!firebase || firebase.apps.length === 0) {
            console.log("no firebase yet")
            return null
        }
        return firebase.auth()
    }
    async linkWithGoogle() {
        const currentUser = this.getCurrentUser()
        const provider = new firebase.auth.GoogleAuthProvider()
        const user = await currentUser.linkWithPopup(provider)
        return user
    }
    async loginGoogle() {
        const fauth = this.fauth()
        const provider = new firebase.auth.GoogleAuthProvider()
        const user = await fauth.signInWithPopup(provider)
        return user
    }
    async linkWithFacebook() {
        const currentUser = this.getCurrentUser()
        const provider = new firebase.auth.FacebookAuthProvider()
        const user = await currentUser.linkWithPopup(provider)
        return user
    }
    async loginFacebook() {
        const fauth = this.fauth()
        const provider = new firebase.auth.FacebookAuthProvider()
        const user = await fauth.signInWithPopup(provider)
        return user
    }
    createUserWithEmailAndPassword(email, pass) {
        const fauth = this.fauth()
        return fauth.createUserWithEmailAndPassword(email, pass)
    }
    async linkWithPassword(email:string, password:string) {
        const currentUser = this.getCurrentUser()
        var credential = firebase.auth.EmailAuthProvider.credential(email, password)
        const user = await currentUser.linkWithCredential(credential)
        return user
    }
    async loginEmailPass(email, pass) {
        const fauth = this.fauth()
        const user = await fauth.signInWithEmailAndPassword(email, pass)
        return user
    }
    async sendForgotPasswordMail(email) {
        const fauth = this.fauth()
        const res = await fauth.sendPasswordResetEmail(email)
        return res
    }
    async changePassword(password) {
        const user = this.getCurrentUser()
        const res = await user.updatePassword(password)
        return res
    }
    async sendEmailVerification() {
        const currentUser = this.getCurrentUser()
        if (currentUser.emailVerified)
            return
        if (this.alreadySentVerification)
            return
        await currentUser.sendEmailVerification()
        this.alreadySentVerification = true
    }
    getCurrentUser() {
        const fauth = this.fauth()
        if (!fauth)
            return null
        const currUser = fauth.currentUser
        if (!currUser)
            return null
        return currUser
    }
    async getClaims() {
        const user = this.getCurrentUser()
        if (!user)
            return null
        const idTokenResult = await user.getIdTokenResult()
        return idTokenResult.claims
    }
    async getIdToken() {
        const user = this.getCurrentUser()
        if (!user)
            return null
        const idToken = await user.getIdToken()
        return idToken
    }
    async isUserAdmin() {
        const claims = await this.getClaims()
        const role = claims.role
        if (role && role == "admin")
            return true
        return false
    }
    async isSeller() {
        const claims = await this.getClaims()
        const role = claims.role
        if (role && role == "seller")
            return true
        return false
    }
    logout() {
        firebase.auth().signOut()
        gotoPath("/")
    }
    async applyActionCode(actionCode) {
        const auth = firebase.auth()
        const res = await auth.applyActionCode(actionCode)
        return res
    }
    async checkActionCode(actionCode) {
        const auth = firebase.auth()
        const info = await auth.checkActionCode(actionCode)
        return info
    }

    async verifyPasswordResetCode(actionCode) {
        const auth = firebase.auth()
        const email = await auth.verifyPasswordResetCode(actionCode)
        return email
    }

    async confirmPasswordReset(actionCode, newPassword) {
        const auth = firebase.auth()
        const res = await auth.confirmPasswordReset(actionCode, newPassword)
        return res
    }
}
export default new Authentication()
