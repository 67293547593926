import { SvelteSubject,clonedObservable } from "@common/store/reactive"
import { getCartLines, ResetLines } from "./cartStore"
import { getSelectedDepartment } from "@common/store/departmentPublicStore"
import rest from "@common/services/restService"
import config from "@common/config"
import type { OrderViewModel, PlaceOrderPostModel } from "@api"

const subject = new SvelteSubject<OrderViewModel>({id: "null", location: "n/a"})
export const orderConfirmationStore = clonedObservable(subject)

export const createOrderFromCart = async (note: string) => {
    const lines = getCartLines()
    const selDep = getSelectedDepartment()
    const orderPostModel: PlaceOrderPostModel = {
        departmentId: selDep.id,
        noteFromBuyer: note,
        lines
    }
    
    const order = await rest.post<OrderViewModel>("order", orderPostModel)
    if (order && order.id) {
        //const url = `${baseUrl}/Payment?orderid=${order.id}`
        //window.open(url, "_blank")
        //window.location.replace(url)
        subject.next(order)
        console.log("order initial placement")
        console.log(`Order id ${order.id}`)
        ResetLines() //remove from cart
    }
    
}