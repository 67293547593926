import {LitElement, html, css, unsafeCSS} from "lit"
import {customElement} from "lit/decorators.js"
import { goto } from "@common/routing/router"
import { materialIconsStyle } from "@common/style/stylesheets"

@customElement('locations-widget')
export class LocationsWidget extends LitElement {
    private locations = [{
        "name": "Bergen",
		"routeKey": "bergen",
    },{
        "name": "Drammen",
		"routeKey": "drammen",
    },{
        "name": "Stavanger",
		"routeKey": "stavanger",
    }]
    static styles = css`
        :host {
            background: var(--mdc-theme-background);
            display: inline-flex;
            flex-direction: column;
            max-width: 100%;
            padding: 1rem;
            /* box-shadow: 0 0 20px var(--mdc-theme-text-hint-on-background), 0 0 5px var(--mdc-theme-text-primary-on-background); */
            gap: 0.6rem;
        }
        .folder {
            display: inline-flex;
            overflow-x: auto;
            justify-content: flex-start;
            flex-direction: row;
            gap: 0.6rem;
        }
        .folder.all {
            justify-content: center;
        }
        ${unsafeCSS(materialIconsStyle)}
        .location {
            display: inline-block;
        }
        .location a {
            color: black;
            text-decoration: none;
            border-bottom: 2px solid var(--mdc-theme-primary);
        }
        .location a:visited {
            color: black;
        }
        a.order-link {
            background-color: var(--mdc-theme-text-hint-on-light);
        }
        a.order-link:hover {
            background-color: var(--mdc-theme-primary);
        }
        a.order-link h3 {
            display: inline-block;
            color: var(--mdc-theme-text-primary-on-dark);
            padding: 0.5rem;
            margin: 0.2rem;
            border-radius: 3px;
        }
    `;

    render() {
        return html`
            <div class="folder">
                ${this.locations.map(l => {
                    return html`<div class="location">
                        
                        <a href="/${l.routeKey}" @click=${goto}>
                            <direction-sign text=${l.name}></direction-sign>
                        </a>
                    </div>`
                })}
            </div>
        `;
    }
    // <!-- <div class="folder all">
    //<a href="/locations" class="order-link" @click=${goto}>
    //<h3>Se flere lokasjoner</h3>
    //</a>
    //</div> -->
}