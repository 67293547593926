<script lang="ts">
    import { userIsLoggedIn, logOut, userAuthInfo } from "@common/store/userProfile"
    import Link from "@common/components/Nav/Link.svelte"
    import auth from "@common/services/authentication"
    import { goto } from "@common/routing/router"
    const logOff = () => {
        auth.logout()
        logOut()
    }
</script>
<style>
    nav {
        color: var(--mdc-theme-primary);
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        height: auto;
    }
    .centre {
        text-align: center;
    }
    .material-icons.login {
        font-size: 3rem;
    }
    .material-icons.logout {
        font-size: 2rem;
        cursor: pointer;
    }
    .main-menu-items {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 0.5rem;
        text-align: center;
    }
    .main-menu-items a {
        font-size: large;
    }
    .bottom {
        margin-bottom: 2rem;
    }
    a {
        background: var(--mdc-theme-primary);
        color: var(--mdc-theme-on-primary);
        text-decoration: none;
        border-radius: 6px;
        padding: 0.5rem 1rem;
    }
</style>
<nav>
    {#if $userIsLoggedIn}
        <div class="centre">
            <Link route="/account">
                <span class="material-icons login">account_circle</span>
            </Link>
        </div>
    {:else}
        <div class="centre login-wrapper">
            &nbsp;
            <!-- <Link route="/signin" langCode="login" color="light" background="dark" />
            <Link route="/signup" langCode="new-user" color="primary" background="light" /> -->
        </div>
    {/if}

    <div class="main-menu-items">
        
        <a on:click={goto} href="/membership">
            Medlemskap
        </a>
        <br>
        <a on:click={goto} href="/diagnoses">
            Dine diagnoser
        </a>
        
    </div>
    
    {#if $userIsLoggedIn}
        <div class="centre bottom">
            <span class="material-icons logout" on:click={logOff}>
                logout
            </span>
        </div>
    {/if}
</nav>