<script lang="ts">
    import type { Toast } from "@common/models/interfaces"
    import toastStore from "@common/store/toast"
    
    let top = (i: number) => {
        const distance = 15 + (i*44);
        return `${distance}px`;
    }
    let onclick = (t: Toast) => {
        if (t.click)
            t.click();
        toastStore.remove(t);
    }
</script>

<style>
    .toast-container {
        position: fixed;
        z-index: 999;
    }
    .top {
        top: 15px;
    }
    .bottom {
        bottom: 15px;
    }
    .left {
        left: -500px;
        animation: slide-left 0.5s forwards;
        animation-delay: 0.5s;
    }
    @keyframes slide-left {
        100% { left: 0; }
    }
    .right {
        right: -300px;
        animation: slide-right 0.5s forwards;
        animation-delay: 0s;
    }
    @keyframes slide-right {
        100% { right: 0; }
    }
    .center {
        left: 50%;
        transform: translateX(-50%);
    }
    .toast {
        height: 38px;
        line-height: 38px;
        padding: 0 20px;
        margin: 0 15px;
        box-shadow: 0 1px 3px rgba(255, 255, 255, 0.12), 0 2px 4px rgba(255, 255, 255, 0.98);
        color: #fff;
        max-width: 300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        animation: fadein 1s;
        border-radius: 0.2rem;
        opacity: 0.9;
        cursor: pointer;
    }
    .big {
        height: 114px;
        max-width: 500px;
        white-space: normal;
    }
    .info {
        font-weight: bold;
        background-color: var(--info);
    }
    .success {
        background-color: var(--success);
    }
    .error {
        background-color: var(--red-dark);
    }
    .default {
        background-color: var(--mdc-theme-background);
    }
    @keyframes pulse {
        0% { background-color: var(--mdc-theme-background); }
        100% { background-color: var(--red-dark); }
    }
    @keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 0.8; }
    }
</style>
{#each $toastStore as toast, i}
    <div class="toast-container {toast.position}" 
        style="top: {top(i)}"
        on:click={() => onclick(toast) }>
        <div class="toast {toast.type} {toast.size}">{@html toast.msg}</div>
    </div>
{/each}
