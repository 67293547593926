import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"
import { imageAssetStore, getImageAsset } from "@common/store/assetsStore"
import type { ImageFileGroupViewModel, ImageSizeType } from "@api"
// import {until} from "lit/directives/until.js"
import { Subscription } from "rxjs"

@customElement('asset-image-viewer')
export class imageViewer extends LitElement {
    private sub = Subscription.EMPTY
    private img: ImageFileGroupViewModel
    static styles = css`
        :host {
            display: block;
            max-width: 100%;
        }
        .no-img {
            display: inline;
        }
    `;

    @property({attribute: true})
    id: string

    @property({attribute: false})
    wantedsize: ImageSizeType

    connectedCallback() {
        super.connectedCallback()
        this.sub = imageAssetStore.subscribe(assets => {
            this.img = assets.find(i => i.id == this.id)
            if (this.img)
                this.requestUpdate()
        })
    }
    disconnectedCallback() {
        super.disconnectedCallback()
        this.sub.unsubscribe()
    }
    getImage() {
        const tryNewImg = getImageAsset(this.id)
        if (tryNewImg)
            this.img = tryNewImg

        const image = this.img
        if (this.wantedsize) {
            return html`<image-viewer class="thumbnail" .image=${image} .wantedsize=${this.wantedsize} alt=${image.name}></image-viewer>`
        }
        return html`<image-viewer class="thumbnail" .image=${image} alt=${image.name}></image-viewer>`
    }
    render() {
        
        if (this.id && this.id != "00000000-0000-0000-0000-000000000000" && this.img) {
            return html`${this.getImage()}`
        } else
            return html`<div class="no-img"></div>`
    }
}