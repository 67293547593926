<script>
    export let pages = []
    export let currentIndex = 0
    export let base = "/"
    export let param = null
    import SiblingLink from "./SiblingLink.svelte"

</script>
<style>
    nav.nested-nav {
        display: flex;
        justify-content: start;
        padding-bottom: 0.5rem;
    }
    .nested-nav-item {
        display: inline-flex;
        background: white;
        font-size: larger;
        padding-bottom: 0.5rem;
        margin-right: 0.5rem;
    }
    .nested-nav-item.selected {
        border-bottom: var(--bildoktoren-blue) 2px solid;
    }
</style>
<nav class="nested-nav">
    {#each pages as page, i}
        <span class="nested-nav-item" class:selected={i === currentIndex}>
            <SiblingLink base={base} param={page.id} langCode="{page.label}" />
        </span>
    {/each}
</nav>
<div class="nested-view">
    {#if param}
        <svelte:component this={pages[currentIndex].component} param={param} />
    {:else}
        <svelte:component this={pages[currentIndex].component} />
    {/if}
</div>