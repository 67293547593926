import { SvelteSubject, } from "@common/store/reactive"
import { userAuthInfo } from "@common/store/userProfile"
import rest from "@common/services/restService"

import type { SubscriptionState } from "@api"
const urlsub = "paymentApi/subscription"

const substateSubject = new SvelteSubject<SubscriptionState>({ isActive: false })
export const substate = substateSubject.asObservable()

export const refreshSubState = async () => {
    const state = await rest.get<SubscriptionState>(urlsub)
    substateSubject.next(state)
}

userAuthInfo.subscribe(ua => {
    if (ua.loggedIn)
        refreshSubState()
    else
        substateSubject.next({ isActive: false })
})