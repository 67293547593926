<script lang="ts">
    import Check from "@app/components/Signs/Check.svelte"
    import { substate } from "@app/store/paymentsStore"
    import { goto } from "@common/routing/router"
    //import { curRoute } from "@common/routing/router"
    import { userAuthInfo } from "@common/store/userProfile"
</script>
<style>
    .landing {
        margin: 0 0.5rem;
    }
    .fullHeight {
        height: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
    }

    .login {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .loggedin {
        height: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: space-between;
        padding: 3rem 15% 1rem 15%;
        box-sizing: border-box;
    }
    .menu {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
    .logo {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    h1.headline {
        color: var(--mdc-theme-primary);
    }
    a {
        display: inline-flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: var(--bildoktoren-purple-trans);
        color: var(--mdc-theme-on-primary);
        text-decoration: none;
        border-radius: 6px;
        padding: 0.5rem 1rem;
    }
    a.link {
        padding: 0 1rem;
    }
    @media only screen and (max-width: 640px) {
        .loggedin {
            padding: 1%;
        }
    }
</style>
<article class="landing fullHeight">
    {#if $userAuthInfo.loggedIn}
        <div class="loggedin">
            <div class="menu">
                <h1 class="headline">Min Side</h1>
                
                <a class="link" on:click={goto} href="/membership">
                    <h2>Medlemskap</h2>
                    <span class="material-icons">navigate_next</span>
                </a>
                
                <a class="link" on:click={goto} href="/diagnoses">
                    <h2>Diagnoser</h2>
                    <span class="material-icons">navigate_next</span>
                </a>
                
                <a class="link" on:click={goto} href="/account">
                    <h2>Konto</h2>
                    <span class="material-icons">navigate_next</span>
                </a>
                
            </div>
            
            <div class="logo">
                <img src="https://storage.googleapis.com/bildoktoren-no.appspot.com/assets/logos/bd-logo-white-inside-192-fs8.png" alt="bildoktoren logo" height="192" width="192">
            </div>
            
        </div>
        
    {:else}
        <div class="login">
            <img src="https://storage.googleapis.com/bildoktoren-no.appspot.com/assets/logos/bd-logo-white-inside-192-fs8.png" alt="bildoktoren logo" height="192" width="192">
            <p><br></p>
            <a href="/signin" on:click={goto}>
                <h1>Logg inn Min Side</h1>
            </a>
        </div>
    
    {/if}
</article>
