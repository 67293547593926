<script lang="ts">
    import Link from "@common/components/Nav/Link.svelte"
    export let langCode: string
    export let param = ""
    export let base = ""
    export let icon = ""
    
    let route = ""

    $: if (param) {
        route = base
        if (param) {
            route = `${route}/${param}`
        }
    }
</script>

<Link route={route} langCode={langCode} icon={icon} />