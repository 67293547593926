<script lang="ts">
    import { onMount, onDestroy } from "svelte"
    import LoadingIndicator from "@common/components/LoadingIndicator.svelte"
    import { userAuthInfo } from "@common/store/userProfile"
    import { userProfile,  update } from "@common/store/userProfile"
    import lang from "@common/services/lang"
    import toast from "@common/services/toastService"
    import type { Subscription } from "rxjs"
    import type { UserViewModel } from "@api"
    

    let subscription: Subscription
    let user: UserViewModel = { id: "", name: "...", email: "" }
    onMount(() => {
        subscription = userProfile.subscribe((value) => {
            user = value
        })
    })
    onDestroy(() => subscription.unsubscribe() )

    let updateUser = async () => {
        try {
            await update(user)
            toast.success(`${lang("userprofile")} ${lang("saved")}`)
        } catch (ex)  {
            errorHandler(ex)
        }
    }

    const errorHandler = (ex: Error) => {
        throw ex
    }

</script>
<style>
    fieldset {
        border: none;
        margin: 0;
        padding: 0;
    }
    input {
        margin-bottom: 0.3rem;
    }
</style>

<div class="subcontent">
    {#if $userAuthInfo.loggedIn}
        <form>
            <fieldset disabled={!$userProfile.id}>
                <input type="text" bind:value={user.name} placeholder="{lang('name')}" /><br>
                <input type="text" bind:value={user.address} placeholder="{lang('address')}" /><br>
                <input type="text" bind:value={user.postalCode} placeholder="{lang('postal-code')}" /><br>
                <input type="text" bind:value={user.place} placeholder="{lang('place')}" /><br>
            </fieldset>
            <button on:click|preventDefault={updateUser}>{lang('save')}</button>
            {#if !$userProfile.id}
                <LoadingIndicator />
            {/if}
        </form>
    {/if}
</div>
