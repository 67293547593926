<script lang="ts">
    let dialogEl: HTMLDivElement
    export let open = false
    export let background = "--mdc-theme-background"
    export let onClose = null
    let closeDlg = () => {
        open = false
        if (onClose)
            onClose()
    }
</script>
<style>

    div.modal-dialog {
        box-sizing: border-box;
        display: none;
        position: absolute;
        z-index: 900;
        left: 0;
        top: 0;
        width: 100vw;
        max-width: 100vw;
        height: 100vh;
        max-height: 100vh;
        overflow: auto;
        background-color: rgb(0,0,0);
        background-color: rgba(0,0,0,0.4);
    }
    div.modal-dialog.open {
        display: block;
    }
    .modal-content {
        box-sizing: border-box;
        border-radius: 6px;
        width: 100vw;
        max-width: 100vw;
        height: 100vh;
        max-height: 100vh;
    }
    .close-btn {
        --mdc-theme-secondary: var(--background-almost-transparent);
        position: fixed;
        top: 0;
        right: 0;
    }
</style>

<div>
    <slot name="btnContent">Open dlg</slot>
</div>
<div class="modal-dialog" bind:this={dialogEl} class:open>
    <mwc-fab mini class="close-btn" 
        icon="close"
        on:click={closeDlg}>
    </mwc-fab>
    <div class="modal-content" style="background: var({background})">
        <slot name="dlgContent">dialog content</slot>
    </div>
</div>

