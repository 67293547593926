import type { PartnerViewModel } from "@api";
import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"

@customElement('partner-list-view')
export class PartnerListView extends LitElement {
    _dateString: string
    _date: Date
    
    static styles = css`
        :host {
            display: block;
        }
    `
    
    @property({attribute: false})
    partner: PartnerViewModel
    
    render() {
        if (!this.partner)
            return html`<span class="nothing"></span>`
        
        return html`${this.partner.name}`
    }
}