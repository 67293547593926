import { SvelteSubject, clonedObservable } from "@common/store/reactive"
import { appInitialized } from "@common/store"
import lang from "@common/services/lang"
import toast from "@common/services/toastService"
import restService from "@common/services/restService"
import restCache from "@common/services/restCache"
import type { ImageFileGroupViewModel, ImageFilePostModel } from "@api"

const url = "assets"

const imageAssetStoreSubject = new SvelteSubject<ImageFileGroupViewModel[]>([])
export const imageAssetStore = clonedObservable(imageAssetStoreSubject)

export const getImageAsset = (id: string) => {
    const all = imageAssetStoreSubject.get()
    return all.find(a => a.id == id)
}

const fetchAllAssets = async () => {
    const assets = await restCache.get<ImageFileGroupViewModel[]>(url)
    imageAssetStoreSubject.next(assets)
    return assets
}
appInitialized.subscribe(value => {
    if (value)
        fetchAllAssets()
})
// inefficient
// export const getAsset = async (id: string) => {
//     let all = imageAssetStoreSubject.getValue()
//     if (Array.isArray(all) && all.length > 0) {
//         const asset = all.find(a => a.id == id)
//         if(asset)
//             return asset
//     }
//     all = await fetchAllAssets()
//     return all.find(a => a.id == id)
// }

export const uploadImageAsset = async (imageAsset: ImageFilePostModel) => {
    let asset: ImageFileGroupViewModel = await restService.post(url, imageAsset)
    toast.success(`${lang("asset")} ${lang("created")}`)
    const currentImageAssets = imageAssetStoreSubject.get()
    imageAssetStoreSubject.next([...currentImageAssets, asset])
    return asset
}

export const deleteImageAsset = async (id) => {
    await restService.remove(`${url}/${id}`)
    toast.success(`${lang("asset")} ${lang("removed")}`)
    const newList = imageAssetStoreSubject.getValue().filter(p => p.id !== id)
    imageAssetStoreSubject.next(newList)
}
