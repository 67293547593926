import {LitElement, html, css } from "lit"
import {customElement} from "lit/decorators.js"
import { departments } from "@common/store/departmentPublicStore"
import type { DepartmentViewModel } from "@api"
import { observe } from "@common/directives/ObservableDirective"
@customElement('dep-listing')
export class DepListing extends LitElement {
    
    static styles = css`
        :host {
            display: block;
        }
        .departments {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 0.5rem;
        }
        .snappable {
            scroll-snap-align: start;
        }
        @media only screen and (max-width: 640px) {
            .departments {
                overflow-x: scroll;
                scroll-snap-type: x mandatory;
                max-width: 100%;
                flex-direction: row;
                flex-wrap: nowrap;
            }
        }
    `
    constructor() {
        super()
    }
    render() {
        return html`
            <div class="departments">
                ${observe(departments, (deps: DepartmentViewModel[]) => {
                    return deps.map(d => {
                        return html`<dep-card class="snappable" .dep=${d}></dep-card>`
                    })
                })}
            </div>
        `
    }
}