
import type firebase from "firebase/app"
import type { LngLatViewModel } from "../../api"

export interface AuthUser {
    loggedIn: boolean
    uid?: string
    email?: string
    emailVerified: boolean
    name?: string
    displayName?: string
    initials?: string
    providerData?: firebase.UserInfo[]
    photoURL?: string
    isAdmin?: boolean
    isSeller?: boolean
}

export interface BdMarker {
    location: LngLatViewModel
    title: string
    popup?: string
}

export interface BrowserRoute {
    path: string
    param?: string
    action?: string
    search?: URLSearchParams
}
export interface Route {
    path: string
    component: any
    title?: string
    description?: string
    param?: string
    action?: string
    search?: URLSearchParams
    mustBeLoggedIn?: boolean
    admin?: boolean
    init?: Function
}

export interface Toast {
    type: string
    size: string
    position: string
    msg: string
    click?: Function
}

export interface LocationDescription extends LngLatViewModel {
    title?: string
    default?: boolean
}

export interface BdEditorState {
    json: any
    html: string
}

export interface WebAction {
    mode?: string
    oobCode?: string
    continueUrl?: string
    lang?: string
}
export interface LocationLookup {
    location?: LngLatViewModel
    placeId?: string
}

export enum KeyNav {
    Left = "ArrowLeft",
    Right = "ArrowRight",
    Up = "ArrowUp",
    Down = "ArrowDown"
}