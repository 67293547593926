import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"

@customElement('distance-viewer')
export class DistanceViewer extends LitElement {

    static styles = css`
        :host {
            display: inline-flex;
    `;

    @property({attribute: true, type: Number}) 
    distance: number;
    
    render() {
        const dist = Math.round(this.distance);
        if (dist < 1000) {
            return html`${dist} m`;
        } else {
            const distKm = Math.round(dist/1000);
            return html`${distKm} km`;
        }
    }
}