<script lang="ts">
    import auth from "@common/services/authentication"
    import { userAuthInfo } from "@common/store/userProfile"
    import NestedViewWithNav from "@common/components/Nav/NestedViewWithNav.svelte"
    import Info from "./Info.svelte"
    import Security from "./Security.svelte"
    import lang from "@common/services/lang"
    
    export let param: string

    let pages = [
        {
            id: "index",
            label: "account",
            component: Info
        },{
            id: "authinfo",
            label: "security",
            component: Security
        }
    ]

    let currentIndex = 0
    let currentPage = pages[currentIndex]
    $: if (param) {
        currentPage = pages.find(p => p.id == param)
        currentIndex = pages.indexOf(currentPage)
        if (!currentPage) {
            currentIndex = 0
            currentPage = pages[currentIndex]
        }
    }
</script>
<style>
    header {
        display: flex;
        flex-direction: column;
    }
    .logout {
        display: inline-block;
        cursor: pointer;
        background: var(--mdc-theme-primary);
        color: var(--mdc-theme-on-primary);
        border-radius: 6px;
        padding: 0.5rem 1rem;
    }
</style>
<article class="default">
    <header>
        <h2>{lang('userprofile')}</h2>
        <div>
            <span class="logout" on:click={auth.logout}>
                Logg ut
            </span>
        </div>
    </header>
    <section class="left">
        {#if $userAuthInfo.loggedIn}
            <NestedViewWithNav base="/account" pages={pages} currentIndex={currentIndex}/>
        {:else}
            <p>Not logged in</p>
        {/if}
    </section>
</article>
