<script lang="ts">
    import type { DiagnoseViewModel } from "@api"
    import Attachments from "./Attachments.svelte"
    export let diagnose: DiagnoseViewModel = { shortDescription: "", description: {html: "<p>diagnose</p>"}, departmentId: ""}
</script>
<style>
    section {
        text-align: left;
    }
    h2 {
        text-align: center;
    }
</style>
{#if diagnose.carData}
    <h2>{diagnose.carData.registrationNumber}</h2>
{/if}
<car-data car={diagnose.carData}></car-data>

<h3>{diagnose.shortDescription}</h3>

{#if diagnose.description && diagnose.description.html}
    <section>
        {@html diagnose.description.html}
    </section>
{:else}
    <p>Ingen beskrivelse</p>
{/if}

<Attachments diagnose={diagnose} />