<script lang="ts">
    import boot from "./boot"
    import { onMount } from "svelte"
	import "@app/routing"
	import { curRoutePath, goto } from "@common/routing/router"
	import { appInitialized } from "@common/store"
	
	import Toast from "@common/components/Toast.svelte"
	import { openMainMenu } from "@common/store"
    import "@app/services/pagestate"
    import "@common/services/userauth"
    import "@common/services/userprofile"
    import Container from "@app/routing/Container.svelte"
    
	import SideMenu from "@common/components/Nav/SideMenu.svelte"
	import MainMenu from "@app/MainMenu.svelte"
	
    onMount(() => {
        boot.initialize()
        if (!history.state) {
            window.history.replaceState({ path: window.location.pathname }, "", window.location.href)
        }
		curRoutePath.subscribe(val => {
			setTimeout(() => {
				window.scrollTo({
					top: 0,
					left: 0,
					behavior: "smooth"
				})
			}, 100)
		})
    })
	const infoline = ""

	const openMenu = () => {
		openMainMenu.set(true)
	}
    
</script>
<style>
	main {
		padding: 0;
		margin: 0 auto;
		display: grid;
		grid-template-rows: auto 1fr auto;
		box-sizing: border-box;
		min-height: 100%;
		width: 1600px;
		overflow-y: auto;
		overflow-x: hidden;
	}
	main header {
		background: var(--bd-background-primary);
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}
	main footer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		/* background: var(--mdc-theme-primary); */
		z-index: 500;
	}
	/* #header-upper {
		width: 1600px;
		box-sizing: border-box;
	}
	#header-lower {
		width: 1600px;
	} */
	
	.info-line {
		background: var(--mdc-theme-primary);
		color: white;
		width: 1600px;
		padding: 0.3rem 0.6rem;
		font-size: 0.9rem;
		text-align: center;
		white-space: nowrap;
		overflow: hidden;
	}
	.info-line.top {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 0 0.6rem;
		line-height: 1.5rem;
	}

	.material-icons.menu {
		font-size: 3rem;
		cursor: pointer;
	}

	.right {
		display: inline-flex;
		flex-direction: row;
		justify-content: center;
        align-items: center;
		gap: 2rem;
	}

	.right a {
		color: var(--mdc-theme-on-primary);
		text-decoration: none;
	}
	@media only screen and (max-width: 1600px) {
		main, header {
			width: 100vw;
		}
        /* #header-upper, #header-lower {
			width: 100vw;
	    } */
		
		.info-line {
			width: 100vw;
		}
    }
	/* @media only screen and (max-width: 640px) {
		#header-upper {
			padding: 0.5rem 1rem;
		}
	} */
</style>

<main>
	<header>
		<section class="info-line top">
			<figure class="bildoktoren-logo home">
				{#if $appInitialized}
					<a href="https://www.bildoktoren.no">
						<img src="https://storage.googleapis.com/bildoktoren-no.appspot.com/assets/logos/bd-logo-white-inside-48-fs8.png" alt="bildoktoren logo" height="48" width="48">
					</a>
					
				{/if}
			</figure>
			<span>
				{infoline}
			</span>
			<div class="right">
				<a href="/" on:click={goto}>
					<h3>Min side</h3>
				</a>
				<span class="material-icons menu" on:click={openMenu}>
					menu
				</span>
			</div>
			
		</section>
		
	</header>
	{#if $appInitialized}
		<Container />
	{/if}
	
	<footer>
		<section class="info-line bottom">
			{infoline}
		</section>
	</footer>
	<SideMenu>
		{#if $appInitialized}
			<MainMenu />
		{/if}
	</SideMenu>
	<Toast />
</main>
