import lang from "@common/services/lang"
import auth from "@common/services/authentication"
import { gotoPath } from "@common/routing/router"
export const uiLoginGoogle = async () => {
    try {
        await auth.loginGoogle();
        await loginCommon();
        return ""
    } catch(err) {
        return errorHandler(err);
    }
}
export const uiLoginFb = async () => {
    try {
        await auth.loginFacebook();
        await loginCommon();
    } catch(err) {
        return errorHandler(err);
    }
}
export const uiLoginEmailPass = async (email: string, pass: string) => {
    try {
        await auth.loginEmailPass(email, pass);
        await loginCommon();
        return ""
    } catch(err) {
        return errorHandler(err);
    }
}

export const uiForgotPassword = async (email: string) => {
    try {
        if (!email) {
            return lang("email-must-be-filled-in");
        }
        const res = await auth.sendForgotPasswordMail(email);
        return lang("forgot-password-link-sent");
    } catch(err) {
        return lang(err.code);
    }
};

export const uiRegisterUserEmail = async (email:string, pass: string) => {
    try {
        await auth.createUserWithEmailAndPassword(email, pass)
        return `Registrering av ${email} gjennomført, sjekk epost for å bekrefte`
    } catch(err) {
        return errorHandler(err)
    }
}
const errorHandler = (err) => {
    let msg = ""
    msg = lang(err.code); //(${err.code}) translate? i.e. "auth/wrong-password" is returned here
    if (msg == err.code) {
        msg = `${err.message} (${err.code})`;
        msg = `${msg}, email:${err.email}`;
        msg = `${msg}, cred:${err.credential}`;
    }
    return msg
};

const loginCommon = async () => {
    gotoPath("/");
};
