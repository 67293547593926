import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"
import type { ImageFileGroupViewModel } from "@api"
import {TouchController} from "@common/controllers/TouchController"
import { KeyboardController } from "@common/controllers/KeyboardController"
import { KeyNav } from "@common/models/interfaces"

@customElement('image-list-viewer')
export class ImageListViewer extends LitElement {
    // private touch = new TouchController(this)
    private kb = new KeyboardController(this)

    constructor() {
        super();
        // this.touch.leftFn = () => {
        //     this.scrollLef()
        // }
        // this.touch.rightFn = () => {
        //     this.scrollRight()
        // }
        this.kb.NavFunction = (key: KeyNav) => {
            if (key == KeyNav.Left)
                this.scrollLef()
            else if (key == KeyNav.Right)
                this.scrollRight()
        }
    }
    static styles = css`
        :host {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 100%;
            max-height: 100%;
        }
        
        .image-wrapper {
            display: block;
            cursor: pointer;
            width: 100%;
            height: 100%;
            max-height: 100%;
            text-align: center;
            /* max-width: 100vw; */
            /* padding: auto; */
            overflow: hidden;
            /* scroll-snap-align: start; */
            /* border: 2px blue solid; */
            box-sizing: border-box;
        }
        image-viewer {
            max-height: 100%;
        }
        
        .image-wrapper:hover {
            box-shadow: var(--bd-box-shadow-hover);
        }
        .full {
            height: 100%;
            width: 100%;
        }
        .floating {
            position: fixed;
            top: 90%;
            --mdc-theme-secondary: var(--background-almost-transparent);
        }
        
        .left {
            left: 10px;
        }
        .right {
            right: 10px;
        }
        .debugger {
            display: inline-block;
            position: fixed;
            color:red;
            top: 0;
            left: 0;
        }
    `
    @property({attribute: false})
    images: ImageFileGroupViewModel[]

    @property({attribute: true, type: Number})
    selectedIndex: number

    scrollLef() {
        if (this.selectedIndex == 0)
            return
        this.selectedIndex -= 1
        this.emitSelectEvent(this.selectedIndex)
    }
    scrollRight() {
        // const wrapper = this.shadowRoot.querySelector(".image-wrapper")
        // this.setDebug(wrapper as HTMLElement)
        if ((this.images.length-1) == this.selectedIndex)
            return
        this.selectedIndex += 1
        this.emitSelectEvent(this.selectedIndex)
    }
    emitSelectEvent(num: number) {
        var evt = new CustomEvent('setselectedindex', {
            bubbles: true,
            composed: true,
            detail: { num }
        })
        this.dispatchEvent(evt)
    }
    setDebug(wrapper: HTMLElement){
        const debugEl = this.shadowRoot.querySelector(".debugger")
        debugEl.innerHTML = `
            viewport ${window.screen.width} x ${window.screen.height}<br>
            wrapper client ${wrapper.clientWidth} x ${wrapper.clientHeight}<br>
            wrapper offset ${wrapper.offsetWidth} x ${wrapper.offsetHeight}<br>
            wrapper scroll ${wrapper.scrollWidth} x ${wrapper.scrollHeight}
        `
    }
    validateSelectedIndex() {
        if (this.selectedIndex == undefined || this.selectedIndex < 0 || isNaN(this.selectedIndex)) {
            this.selectedIndex = 0
            this.emitSelectEvent(this.selectedIndex)
        }
        if (this.selectedIndex > (this.images.length-1)) {
            this.selectedIndex = this.images.length-1
            this.emitSelectEvent(this.selectedIndex)
        }
    }
    render() {
        if (!this.images)
            return html`<p>Ingen assets</p>`
        
        this.validateSelectedIndex()
        let image = this.images[this.selectedIndex]
        
        return html`
            <mwc-fab class="floating left" 
                icon="chevron_left"
                @click=${this.scrollLef}>
            </mwc-fab>
            <mwc-fab class="floating right" 
                icon="chevron_right"
                @click=${this.scrollRight}>
            </mwc-fab>
            
            <div class="image-wrapper">
                <image-viewer .image=${image} alt=${image.name}></image-viewer>
            </div>
        
            
        `
    }
}