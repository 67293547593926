import auth from "@common/services/authentication.js"
import toast from "@common/services/toastService.js"
import lang from "@common/services/lang.js"
import config from "@common/config"
let baseUrl = config.apiurl

const get = async (url) => {
    const res = await fetchWithAuth("GET", url)
    const json = await resHandler(res)
    return json
};
const post = async (url, data) => {
    const res = await fetchWithAuth("POST", url, data)
    const json = await resHandler(res)
    return json
};

const put = async (url, data) => {
    const res = await fetchWithAuth("PUT", url, data)
    const json = await resHandler(res)
    return json
};

const remove = async (url) => {
    const res = await fetchWithAuth("DELETE", url)
    const json = await resHandler(res)
    return json
};

const fetchWithAuth = async (method, url, data) => {
    const fullUrl = `${baseUrl}/${url}`
    let req = {
        method,
        headers: {
            "Content-Type": "application/json"
        }
    };
    const bearer = await getBearer();
    if (bearer)
        req.headers.Authorization = bearer
    
    if (data) {
        req.body = JSON.stringify(data)
    }
    console.log(fullUrl)
    const res = await fetch(fullUrl, req)
        .catch((error) => {
            let errorFetchMsg = lang("error-fetching")
            console.error(error.message)
            toast.error(errorFetchMsg)
            throw new Error(errorFetchMsg)
        });
    return res
};

const resHandler = async (res) => {
    if (res.ok) {
        if (res.status === 200 || res.status === 201) {
            const json = await res.json()
            return json
        } else {
            return;
        }
    } else {
        console.log("error status", res.status, res.statusText)
        
        let errorFetchMsg = lang("error-fetching")
        if (res.status >= 400 && res.status < 500 && !res.bodyUsed) {
            try {
                const pd = await res.json()
                console.log(pd)
                if (pd.errors) {
                    const arrFieldsWithErrors = Object.keys(pd.errors)
                    const errors = arrFieldsWithErrors.map(field => {
                        const fieldTrans = lang(field)
                        const errorMessages = pd.errors[field]
                        let errMsg = ""
                        const msgsTrans = errorMessages.map(msg => {
                            if (msg.includes("is required"))
                                return `${fieldTrans} ${lang("field-is-required")}`
                            else
                                return lang(msg)
                        })
                        errMsg = msgsTrans.join(',')
                        return errMsg
                    })
                    errorFetchMsg = errors.join(",")
                }
                else if (pd.title)
                    errorFetchMsg = lang(pd.title)
                else
                    errorFetchMsg = lang(`status${res.status}`)
            }
            catch (ex) {
                console.log("could not read problem description")
            }
        }
        
        if (res.status !== 403)
            toast.error(errorFetchMsg)
        const message = res.bodyUsed ? res.statusText : await res.text()
        console.log(message)
        throw new Error(errorFetchMsg)
    }
};
const getBearer = async () => {
    const idToken = await auth.getIdToken()
    if (!idToken)
        return null;
    return `Bearer ${idToken}`
};
export default { get, post, put, remove }