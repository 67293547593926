export default class SortFuncs {
    private sortstate: any = {};

    getNoSort() {
        return (a, b) => 1;
    }
    getSortString(col: string, desc?: boolean) {
        if (desc)
            return (a, b) => (a[col] === b[col]) ? 0 : (a[col] > b[col]) ? -1 : 1;
        return (a, b) => (a[col] === b[col]) ? 0 : (a[col] > b[col]) ? 1 : -1;
    }
    getSortBoolean(col: string, desc?: boolean) {
        if (desc)
            return (a, b) => (a[col] === b[col]) ? 0 : a[col] ? -1 : 1;
        return (a, b) => (a[col] === b[col]) ? 0 : a[col] ? 1 : -1;
    }
    getSortFn(sortArr: any[], column: string) {
        if (sortArr.length === 0)
            return this.getNoSort();
        
        const firstRow = sortArr[0];
        const col = firstRow[column];
    
        if (col === null || col === undefined)
            return this.getNoSort();
        
        let sortFn = this.getSortString(column);
        const type = typeof(col);
        
        const desc = this.sortstate.hasOwnProperty(column) && this.sortstate[column] === "asc" ? true : false;
        if (desc)
            this.sortstate[column] = "desc";
        else
            this.sortstate[column] = "asc";
        
        switch (type) {
            case "boolean":
                sortFn = this.getSortBoolean(column, desc)
                break;
            default:
                sortFn = this.getSortString(column, desc);
                break;
        }
        
        return sortFn;
    }
    sortAppl() {
        const col1 = "pendingApplication";
        const col2 = "startedApplication";
        const desc = this.sortstate.hasOwnProperty(col1) && this.sortstate[col1] === "asc" ? true : false;
        if (desc)
            this.sortstate[col1] = "desc";
        else
            this.sortstate[col1] = "asc";
        
        let sortFn;
        if (desc) {
            sortFn = (a, b) => {
                if (a[col1] === b[col1]) {
                    return (a[col2] === b[col2]) ? 0 : a[col2] ? -1 : 1;
                }
                if (a[col1])
                    return -1;
                return 1;
            }
        } else {
            sortFn = (a, b) => {
                if (a[col1] === b[col1]) {
                    return (a[col2] === b[col2]) ? 0 : a[col2] ? 1 : -1;
                }
                if (a[col1])
                    return 1;
                return -1;
            }
        }
        //this.sortByFn(sortFn);
    }
}
