//import {schema} from "prosemirror-schema-basic"
import {addListNodes} from "prosemirror-schema-list"
import { Schema } from "prosemirror-model"
import OrderedMap from "orderedmap"

const pDOM = ["p", 0]
const blockquoteDOM = ["blockquote", 0]
const hrDOM = ["hr"]
const preDOM = ["pre", ["code", 0]]
const brDOM = ["br"]


const bdImageNodeSpec = {
    attrs: {id: {default: ""}},
    inline: true,
    group: "inline",
    draggable: true,
    toDOM: node => [
        "asset-image-viewer",
        {
            "id": node.attrs.id,
            class: "bd-inserted"
        }
    ],
    parseDOM: [{
        tag: "asset-image-viewer",
        getAttrs: dom => {
            let id = dom.getAttribute("id")
            return !id ? false : true
        }
    }]
}

const nodes = {
    
    doc: {
        content: "block+"
    },

    paragraph: {
        content: "inline*",
        group: "block",
        parseDOM: [{ tag: "p" }],
        toDOM() { return pDOM }
    },

    blockquote: {
        content: "block+",
        group: "block",
        defining: true,
        parseDOM: [{ tag: "blockquote" }],
        toDOM() { return blockquoteDOM }
    },

    horizontal_rule: {
        group: "block",
        parseDOM: [{ tag: "hr" }],
        toDOM() { return hrDOM }
    },

    heading: {
        attrs: { level: { default: 1 } },
        content: "inline*",
        group: "block",
        defining: true,
        parseDOM: [{ tag: "h1", attrs: { level: 1 } },
            { tag: "h2", attrs: { level: 2 } },
            { tag: "h3", attrs: { level: 3 } },
            { tag: "h4", attrs: { level: 4 } },
            { tag: "h5", attrs: { level: 5 } },
            { tag: "h6", attrs: { level: 6 } }],
        toDOM(node) { return ["h" + node.attrs.level, 0] }
    },

    code_block: {
        content: "text*",
        marks: "",
        group: "block",
        code: true,
        defining: true,
        parseDOM: [{ tag: "pre", preserveWhitespace: "full" }],
        toDOM() { return preDOM }
    },

    // :: NodeSpec The text node.
    text: {
        group: "inline"
    },

    hard_break: {
        inline: true,
        group: "inline",
        selectable: false,
        parseDOM: [{ tag: "br" }],
        toDOM() { return brDOM }
    },
    bdImage: bdImageNodeSpec
}

const emDOM = ["em", 0], strongDOM = ["strong", 0], codeDOM = ["code", 0]

export const marks = {
    link: {
        attrs: {
            href: {},
            title: { default: null }
        },
        inclusive: false,
        parseDOM: [{
            tag: "a[href]", getAttrs(dom) {
                return { href: dom.getAttribute("href"), title: dom.getAttribute("title") }
            }
        }],
        toDOM(node) { let { href, title } = node.attrs; return ["a", { href, title }, 0] }
    },

    em: {
        parseDOM: [{ tag: "i" }, { tag: "em" }, { style: "font-style=italic" }],
        toDOM() { return emDOM }
    },

    strong: {
        parseDOM: [{ tag: "strong" },
            { tag: "b", getAttrs: node => node.style.fontWeight != "normal" && null },
            { style: "font-weight", getAttrs: value => /^(bold(er)?|[5-9]\d{2,})$/.test(value) && null }],
        toDOM() { return strongDOM }
    },

    code: {
        parseDOM: [{ tag: "code" }],
        toDOM() { return codeDOM }
    }
}

//export const bdSchema = new Schema({ nodes, marks })
const BdNodes = OrderedMap.from(nodes)

export const bdSchema = new Schema({
    nodes: addListNodes(BdNodes, "paragraph block*", "block"),
    marks //schema.spec.marks
})