import rest from "@common/services/restService"
import toast from "@common/services/toastService"
import { gotoPath } from "@common/routing/router"
import { userAuthInfo } from "@common/store/userProfile"
import type { UserExistsViewModel } from "@api"
const checkUserProfileExists = async() => {
    const res = await rest.get<UserExistsViewModel>("userprofile/check")
    return res
}

userAuthInfo.subscribe(async user => {
    if (user.loggedIn) {

        const check = await checkUserProfileExists()
        if (check.firstTime) {
            let welcomeText = "Hei, og velkommen som ny bruker!<br>"
            if (!user.emailVerified) {
                welcomeText = `${welcomeText}Du vil få en mail for å bekrefte din epost<br>`
            }
            welcomeText = `${welcomeText}Gjerne fyll ut mer informasjon i skjemaet under`
            toast.biginfo(welcomeText)
            gotoPath("/account")
            return
        }
        if (check.incomplete) {
            toast.biginfo(`Hei, din brukerinformasjon er ikke helt komplett<br>
            Klikk her for å fylle ut`, () => gotoPath("/account"))
        }
    }
})

export default { checkUserProfileExists }