import toastStore from "@common/store/toast"
import type { Toast } from "@common/models/interfaces"
class ToastService {
    info(msg) {
        const toast: Toast = {
            type: "info",
            size: "",
            position: "left",
            msg
        }
        toastStore.add(toast, 10)
    }
    biginfo(msg: string, click?: Function) {
        const toast: Toast = {
            type: "info",
            size: "big",
            position: "left",
            msg
        }
        if (click)
            toast.click = click
        toastStore.add(toast, 20)
    }
    error(msg: string) {
        const toast: Toast = {
            type: "error",
            size: "normal",
            position: "right",
            msg
        }
        toastStore.add(toast)
    }
    success(msg: string) {
        const toast: Toast = {
            type: "success",
            size: "normal",
            position: "right",
            msg
        }
        toastStore.add(toast)
    }
}

export default new ToastService()