import type { BrowserRoute } from "@common/models/interfaces"

class PathBreaker {
    getRoute(p): BrowserRoute {
        let route: BrowserRoute = {
            path: "/",
            param: "",
            action: ""
        };
        if (!p || p === "/") {
            return route;
        }

        const url = new URL(`${location.origin}${p}`)
        if (url.search) {
            route.search = new URLSearchParams(url.search)
        }
        const path = url.pathname
        const slashes = path.match(/\//ig) || [];

        if (slashes.length === 0) {
            return route;
        } else if (slashes.length === 1) {
            route.path = path.replace("/", "");
            return route;
        }

        let pathSplit = path.split("/");

        pathSplit = this.clearEmptyElementsInArray(pathSplit);
        route.path = `${pathSplit.splice(0, 1)}`;
        route.param = `${pathSplit.splice(0, 1)}`;
        if (slashes.length === 3) {
            route.action = `${pathSplit.splice(0, 1)}`;
        }
        return route;
    }

    clearEmptyElementsInArray(arr) {
        for (let i = arr.length - 1; i >= 0; i--) {
            if (!arr[i]) {
                arr.splice(i, 1);
            }
        }
        return arr;
    }
}

export default new PathBreaker();
