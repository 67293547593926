import {LitElement, html, css} from "lit"
import {customElement, property} from "lit/decorators.js"
import { formatterNoDateAndTime, formatterNoDateOnly } from "./DateTime"
@customElement('date-viewer')

export class DateViewer extends LitElement {
    _dateString: string;
    _date: Date;
    
    static styles = css`
        :host {
            display: inline-block;
        }
    `;

    isDate(input) {
        if (Object.prototype.toString.call(input) === "[object Date]" ) 
            return true;
        return false;   
    }
    get date(): string | Date {
        if (this._dateString)
            return this._dateString;
        return this._date
    }
    set date(value: string | Date) {
        if (this.isDate(value)) {
            this._date = value as Date
        } else {
            this._dateString = value as string
            this._date = new Date(value)
        }
        this.requestUpdate();
    }
    @property({attribute: false})
    
    render() {
        if (!this._date)
            return html`N/A`;
        this.setAttribute("title", formatterNoDateAndTime.format(this._date))
        return html`
            ${formatterNoDateOnly.format(this._date)}
        `;
    }
}