import { SvelteSubject } from "@common/store/reactive"
import { ContentStaticIdentifier } from "@api"
import type { ContentPostModel, ContentViewModel } from "@api"
import restCacher from "@common/services/restCache"

const entities: Array<string> = []
    for (let value in ContentStaticIdentifier) {
        if (isNaN(Number(value)))
            entities.push(value)
    }

const contentStoreSubject = new SvelteSubject<Array<string>>(entities)
export const contents = contentStoreSubject.asObservable()

export const getContentPostModel = async (contentId: string | ContentStaticIdentifier) => {
    let vm = await restCacher.get<ContentPostModel>(`content/getpostmodel/${contentId}`)
    if (!vm)
        vm = { identifier: contentId as ContentStaticIdentifier }
    if (!vm.content)
        vm.content = {}
    if (!vm.content.assetContents)
        vm.content.assetContents = []
    return vm
}

export const getContent = async (contentId: string | ContentStaticIdentifier) => {
    const url = `content/${contentId}`
    let vm = await restCacher.get<ContentViewModel>(url)
    if (!vm)
        vm.identifier = contentId as ContentStaticIdentifier
    return vm
}
