import { SvelteSubject, clonedObservable } from "@common/store/reactive"
import restCacher from "@common/services/restCache"
import { cloneDeep } from "lodash-es"
import { map } from "rxjs/operators"
import type { BlogPostViewModel } from "@api"
import type { Observable } from "rxjs"

const blogStoreSubject = new SvelteSubject<BlogPostViewModel[]>([])
export const blogStore = blogStoreSubject.asObservable()

export const blogStoreFiltered: Observable<BlogPostViewModel[]> = blogStoreSubject.pipe(
    map(data => {
        let filterd = data.filter(b => !b.isDraft)
        return cloneDeep(filterd)
    })
)

const fetchPosts = async () => {
    const posts = await restCacher.get<BlogPostViewModel[]>("blog")
    blogStoreSubject.next(posts)
}
fetchPosts()

export const getPost = async (id:string): Promise<BlogPostViewModel> => {
    let post = blogStoreSubject.value.find(p => p.id === id)
    if (!post)
        post = await restCacher.get<BlogPostViewModel>(`blog/${id}`)
    if (!post.body)
        post.body = { html: "", json: ""}
    if (!post.order) {
        post.order = 0
    }
    const postClone = cloneDeep(post)
    return postClone
}
export const getPostByRoute = async (routeKey:string): Promise<BlogPostViewModel> => {
    let post = blogStoreSubject.value.find(p => p.routeKey == routeKey)
    if (!post)
        post = await restCacher.get<BlogPostViewModel>(`blog/byroute/${routeKey}`)
    if (!post.body)
        post.body = { html: "", json: ""}
    if (!post.order) {
        post.order = 0
    }
    const postClone = cloneDeep(post)
    return postClone
}
